<template>
  <v-dialog v-model="isShow" max-width="400px" ref="dialog" @input="updateShow">
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-icon color="#ffffff" :style="{ background: $store.getters.getThemeColor }" size="80" class="mic">mdi-microphone-outline</v-icon>
          </v-col>
          <v-col v-if="timeLeftText" cols="12">
            <div style="color: #a9a9a9; user-select: none" class="d-flex align-center justify-center">{{ timeLeftText }}</div>
          </v-col>
        </v-row>
        <v-card-text style="color: #a9a9a9; user-select: none" class="text-center"> 點擊透明區塊結束錄音 </v-card-text>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import RecordRTC from "recordrtc";

export default {
  name: "VoiceDialog",
  props: ["show"],
  data: () => ({
    isShow: false,
    recordedBlob: null,
    recorder: null,
    stream: null,
    timeId: null, // 定時器編號，用於移除定時器
    defaultTime: 300, // 預設時間(秒)
    timeLeft: null, // 剩餘時間
    timeLeftText: null, // 剩餘時間文字
  }),
  methods: {
    // 更新視窗顯示與多媒體狀態
    updateShow(b) {
      this.stopRecording();
      this.$emit("update:show", b);
    },
    // 開始錄音
    async startRecording() {
      if (this.recording) return this.stopRecording();
      // 獲取用戶多媒體設備，並表示要求類型為音頻
      navigator.mediaDevices
        .getUserMedia({ audio: true, echoCancellation: false })
        .then((stream) => {
          this.stream = stream;
          this.recorder = new RecordRTC(stream, {
            type: "audio",
            mimeType: "audio/wav",
            recorderType: RecordRTC.StereoAudioRecorder,
            numberOfAudioChannels: 1,
          });
          this.recorder.startRecording();
          this.startTime();
          this.recording = true;
        })
        .catch((err) => {
          console.error("錄音失敗", err);
          this.stream.stop();
          clearTimeout(this.timeId);
        });
    },
    // 停止錄音
    stopRecording() {
      // 清除定時器
      clearTimeout(this.timeId);
      this.recorder?.stopRecording(() => {
        // 釋放麥克風權限
        this.stream.stop();
        let blob = this.recorder.getBlob();
        this.$emit("update:blob", blob);
        this.$emit("on-blob", blob);
      });
      this.recording = false;
    },
    /** 開始倒數 */
    startTime() {
      // 設置每秒減少剩餘時間及初始狀態
      if (this.defaultTime) {
        this.timeLeft = this.defaultTime;
        this.timeLeftText = this.timeLeftToText(this.timeLeft);
        this.timeId = setInterval(() => this.timeLeft--, 1000);
      }
    },
    // 數字轉時間
    timeLeftToText(time) {
      if (time) {
        let m = (~~(time / 60)).toString();
        let s = (time % 60).toString();
        m = m.length > 1 ? m : `0${m}`;
        s = s.length > 1 ? s : `0${s}`;
        return `${m}:${s}`;
      } else {
        return "00:00";
      }
    },
  },
  watch: {
    show() {
      setTimeout(() => (this.isShow = this.show), this.show ? 100 : 0);
      if (this.show) {
        this.startRecording();
      }
    },
    timeLeft() {
      if (this.timeLeft) {
        this.timeLeftText = this.timeLeftToText(this.timeLeft);
      } else {
        this.timeLeftText = "00:00";
        this.isShow = false;
        this.updateShow(false);
        clearTimeout(this.timeId);
      }
    },
  },
};
</script>

<style>
.mic {
  left: 50%;
  margin-top: 60px;
  margin-bottom: 60px;
  transform: translate(-50%, 0%);
  border-radius: 50%;
  animation: pulse-animation 1s infinite alternate;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(170, 170, 170, 0.3);
  }
  100% {
    box-shadow: 0 0 0 30px rgba(170, 170, 170, 0.3);
  }
}
</style>
