import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  // plugins: [sessionStoragePlugin],
  state: {
    /** 裝置唯一碼 */
    uuid: "",
    /** 裝置資訊 ID (for FCM) */
    deviceInfoId: "",
    drawerStatus: false,
    /** 當前登入的用戶資料 */
    userData: null,
    /** 當前選擇的社區資料 */
    selectCommunity: null,
    themeColor: "#33a6b8",
    themeColor_light: "#81c7d4",
    themeColor_light2: "#9dcfd9",
    themeColor_light3: "#257885",

    themeColor_light_new: "#91BFC4",
    themeColor_light2_new: "#F2F2F2",

    windowWidth: 1024,
    windowHeight: 768,
    isMobile: false,
    // isAdmin: false,
    jwttoken: null,
    permissions: [],
    // [音箱] 主畫面項目列表
    speakerMainMenuItems: null,
    // 雲對講用戶列表暫存(主要用於優化音箱體驗)
    intercomHouseHoldList: null,
    // 主畫面項目列表
    mainMenuItems: {},
    /** 主畫面項目配置(排序、顯示狀態)
     * - k : title、v : { index<int>, show<bool> } */
    mainMenuConfig: null,
    // 主畫面項目隱藏列表(title)
    mainMenuHideMap: [],
    // [儀表板] 主畫面儀表板顯示項目列表
    mainMenuShowPanels: [
      { key: "Guest", title: "目前訪客", path: { name: "GuestLeave" } },
      { key: "Receive", title: "未領包裹", path: { name: "PostalList" } },
      {
        key: "Return",
        title: "未退物品",
        path: {
          name: "PostalList",
          params: {
            Return: true,
          },
        },
      },
      { key: "Deposit", title: "未領寄物", path: { name: "DepositList" } },
    ],
    // [儀表板] 主畫面儀表板隱藏項目列表
    mainMenuHidePanels: [
      { key: "Facility", title: "公設未離場", path: { name: "FacilityList" } },
      { key: "Feedback", title: "意見未回覆", path: { name: "FeedbackList" } },
      { key: "Activity", title: "本日活動", path: { name: "CalendarView" } },
      { key: "Gas", title: "抄表未登記", path: { name: "GasList" } },
      { key: "MgmtFee", title: "管理費未繳", path: { name: "MgmtFeeList" } },
    ],
    // 用戶詳細顯示項目列表
    houseHoldShowItems: [
      { text: "行動電話", value: "Phone", align: "center", width: "10%" },
      { text: "室內電話", value: "Tel", align: "center", width: "10%" },
      { text: "緊急聯絡人", value: "EmergencyContact", align: "center", width: "10%" },
      { text: "緊急聯絡人電話", value: "EmergencyPhone", align: "center", width: "10%" },
      { text: "汽車車牌", value: "CarPlate", align: "center", width: "10%" },
      { text: "機車車牌", value: "MotorcyclePlate", align: "center", width: "10%" },
      { text: "門禁卡號", value: "DoorCard", align: "center", width: "10%" },
    ],
    // 用戶詳細隱藏項目列表
    houseHoldHideItems: [
      { text: "備註", value: "PS", align: "center", width: "10%" },
      { text: "職業", value: "Profession", align: "center", width: "10%" },
      { text: "車位", value: "ParkingSpace", align: "center", width: "10%" },
      { text: "謂稱", value: "appellation", align: "center", width: "10%" },
      { text: "年齡", value: "Age", align: "center", width: "10%" },
    ],
    // [雲對講] 語音留言紀錄
    voiceMessageList: null,
    // [雲對講] 未接來電紀錄
    voiceMissedCallList: null,
    // [雲對講] 站點列表
    serviceIntercomStationList: null,
  },
  getters: {
    getThemeColor(state) {
      return state.themeColor;
    },
    getThemeColorLight(state) {
      return state.themeColor_light;
    },
    getThemeColorLight2(state) {
      return state.themeColor_light2;
    },
    getThemeColorLight3(state) {
      return state.themeColor_light3;
    },
    getThemeColorLigh_new(state) {
      return state.themeColor_light_new;
    },
    getThemeColorLight2_new(state) {
      // 當為音箱時，顯示為白色
      return state.windowWidth < 900 && state.windowHeight < 550 ? "#FFFFFF" : state.themeColor_light2_new;
    },
    getWindowWidth(state) {
      return state.windowWidth;
    },
    getWindowHeight(state) {
      return state.windowHeight;
    },
    getToken(state) {
      // return ls.get("jt");
      return state.jwttoken;
    },
    getSelectCommunity(state) {
      return state.selectCommunity;
    },
    // getCommunityName(state) {
    //   return state.communityName;
    // },
    getUser(state) {
      return state.userData;
    },
    isMobile(state) {
      return state.isMobile;
    },
    isDrawerShow(state) {
      return state.drawerStatus;
    },
    // hasModule(state, moduleName) {
    //   if (!moduleName) return true;

    //   for (let mi = 0; mi < state.selectCommunity.Module.length; mi++) {
    //     if (state.selectCommunity.Module[mi].Name == moduleName) {
    //       return true;
    //     }
    //   }
    //   return false;
    // },

    /** [音箱] 判斷裝置是否為音箱(依畫面高寬) */
    isSpeaker(state) {
      return state.windowWidth < 900 && state.windowHeight < 550;
    },

    /** [音箱] 獲取音箱主畫面項目列表 */
    getSpeakerMainMenuItems(state) {
      let local = window.localStorage?.getItem("speakerMainMenuItems");
      if (local) local = JSON.parse(local);
      return state.speakerMainMenuItems ?? local;
    },

    /** 獲取主畫面項目配置(排序、顯示狀態) */
    getMainMenuConfig(state) {
      let local = window.localStorage?.getItem("mainMenuConfig");
      return state.mainMenuConfig ?? local ? JSON.parse(local) : local;
    },

    /** 獲取主畫面儀表板項目 */
    getMainMenuPanels(state) {
      let panels = window.localStorage?.getItem("mainMenuPanels");
      panels = panels ? JSON.parse(panels) : panels;
      return state.mainMenuShowPanels && panels ? panels : { show: state.mainMenuShowPanels, hide: state.mainMenuHidePanels };
    },

    /** 獲取住戶資料欄位 */
    getHouseHoldItems(state) {
      let items = window.localStorage?.getItem("houseHoldItems");
      items = items ? JSON.parse(items) : items;
      return state.houseHoldShowItems && items ? items : { show: state.houseHoldShowItems, hide: state.houseHoldHideItems };
    },

    /** [雲對講] 獲取語音留言紀錄 */
    getVoiceMessageList(state) {
      return state.voiceMessageList ?? [];
    },

    /** [雲對講] 獲取語音留言、未接來電紀錄未讀數 */
    getVoiceMessageNotRead(state) {
      let voice = state.voiceMessageList?.filter((e) => !e.isRead).length ?? 0;
      let missed = state.voiceMissedCallList?.filter((e) => !e.isRead).length ?? 0;
      return voice + missed;
    },

    /** [雲對講] 獲取未接來電紀錄 */
    getMissedCalls(state) {
      return state.voiceMissedCallList ?? [];
    },

    /** [雲對講] 當前使用者是否為站點成員 */
    isStationUser(state) {
      let id = state.userData.id;
      let arr = state.serviceIntercomStationList;
      return arr?.some((item) => item.User?.includes(id));
    },
  },
  mutations: {
    login(state, token) {
      state.jwttoken = token;
      window.sessionStorage.setItem("jwttoken", token);
    },
    setUser(state, user) {
      state.userData = user;
      window.sessionStorage.setItem("userData", JSON.stringify(user));
    },
    setDrawerShow(state, isShow) {
      state.drawerStatus = isShow;
    },
    setWindowResolution(state, { width, height }) {
      state.windowWidth = width;
      state.windowHeight = height;
      state.isMobile = width < 769;
    },
    setSelectCommunity(state, community) {
      state.selectCommunity = community;
      window.sessionStorage.setItem("selectCommunity", JSON.stringify(community));
    },
    logout(state) {
      state.jwttoken = null;
      state.menuStatus = null;
      state.userData = null;
      window.sessionStorage.removeItem("jwttoken");
      window.sessionStorage.removeItem("selectCommunity");
      window.sessionStorage.removeItem("userData");
      Vue.prototype.$api.removeDeviceInfo();
    },

    /** 產生 UUID */
    generateUUID(state) {
      let uuid = window.localStorage?.getItem("uuid");
      if (!uuid) {
        // 判斷當 crypto 無法使用時，改用自訂義方法
        if (crypto?.randomUUID) {
          uuid = crypto.randomUUID();
        } else {
          let d = new Date().getTime();
          let d2 = (performance && performance.now && performance.now() * 1000) || 0;
          uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            let r = Math.random() * 16;
            if (d > 0) {
              r = (d + r) % 16 | 0;
              d = Math.floor(d / 16);
            } else {
              r = (d2 + r) % 16 | 0;
              d2 = Math.floor(d2 / 16);
            }
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
          });
        }
      }

      state.uuid = uuid;
      window.localStorage?.setItem("uuid", uuid);
    },

    /** 設置裝置資訊 ID */
    setDeviceInfoId(state, id) {
      state.deviceInfoId = id;
    },

    // ============== 測試方法 ==============

    /** 設置音箱主畫面項目列表 */
    setSpeakerMainMenuItems(state, items) {
      state.speakerMainMenuItems = items;
      window.localStorage?.setItem("speakerMainMenuItems", JSON.stringify(items));
    },

    /** 設置雲對講用戶列表 */
    setIntercomHouseHoldList(state, data) {
      state.intercomHouseHoldList = data;
    },

    /** 暫存當前主畫面項目資料 */
    setMainMenuItems(state, items) {
      state.mainMenuItems = items;
    },

    /** 設置主畫面項目配置(排序、顯示狀態) */
    setMainMenuConfig(state, items) {
      state.mainMenuConfig = items;
      window.localStorage?.setItem("mainMenuConfig", JSON.stringify(items));
    },

    /** 設置主畫面儀表板項目 */
    setMainMenuPanels(state, { show, hide }) {
      state.mainMenuShowPanels = show;
      state.mainMenuHidePanels = hide;
      window.localStorage?.setItem("mainMenuPanels", JSON.stringify({ show, hide }));
    },

    /** 設置住戶資料欄位 */
    setHouseHoldItems(state, { show, hide }) {
      state.houseHoldShowItems = show;
      state.houseHoldHideItems = hide;
      window.localStorage?.setItem("houseHoldItems", JSON.stringify({ show, hide }));
    },

    /** [雲對講] 設置語音留言紀錄 */
    setVoiceMessageList(state, data) {
      state.voiceMessageList = data;
    },

    /** [雲對講] 設置語音留言紀錄 */
    setMissedCalls(state, data) {
      state.voiceMissedCallList = data;
    },

    /** [雲對講] 設置站點列表 */
    setServiceIntercomStationList(state, data) {
      state.serviceIntercomStationList = data;
    },

    // =====================================
  },
  actions: {},
  modules: {},
});
