import axios from "axios";
import store from "@/store";

class APIServer {
  constructor() {
    // 有加 _new 的參數及方法都是使用了新的 URL 暫定功能
    this.url = process.env.VUE_APP_SERVER_URL;
    this.url_new = process.env.VUE_APP_SERVER_URL_NEW;
  }

  // =============== Demo API =================

  /** Demo - 更新儀表板資訊(暴力接值，各別更新版)
   * @param {string} cid 社區ID
   * @param panels 當前請求的對象
   * @param {Function<any>} onUpdate 監聽更新的對象(依更新對象，多次觸發)
   */
  async getCommunityCountData_Demo(cid, panels, onUpdate) {
    const panelsKeys = panels.map((e) => e.key);

    // 原儀表板資料
    if (["Guest", "Receive", "Return", "Deposit"].some((x) => panelsKeys.includes(x))) {
      const infoCount = await this.commonGet2(`/community/${cid}/info_count`);
      onUpdate(infoCount);
    }

    // 公設未離場
    if (panelsKeys.includes("Facility")) {
      let Facility = (await this.commonGet2(`/community/${cid}/facility_use_count`)).count;
      onUpdate({ Facility });
    }

    // 管理費未繳(處理過程較複雜，導致效能極差)
    if (panelsKeys.includes("MgmtFee")) {
      let periodList = await this.commonGet2(`/community/${cid}/mgmtfee/period_list?s=${""}&p=${1}&sb=${""}&sd=${"0"}&pp=${""}`);
      const periodIds = periodList.result.filter((e) => e.ActiveTime && !e.Close).map((e) => e.id);
      let billPayList = [];
      for (let i in periodIds) {
        let list = await this.commonGet2(`/community/${cid}/mgmtfee/period/${periodIds[i]}/bill_pay_list`);
        list = list.filter((e) => (e.NeedPay && (!e.Items || !e.Items.length) ? false : e.Items.reduce((a, b) => a + b) != 0));
        billPayList.push(list);
      }
      let MgmtFee = billPayList.map((e) => e.length).reduce((a, b) => a + b);
      onUpdate({ MgmtFee });
    }

    // 本日活動
    if (panelsKeys.includes("Activity")) {
      const today = new Date();
      const time = today.toLocaleDateString("en-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
      const data = await this.commonGet2(`/community/${cid}/calendar/event_list?st=${time}&et=${time}`);
      let Activity = data?.length ?? 0;
      onUpdate({ Activity });
    }

    // 抄表未登記
    if (panelsKeys.includes("Gas")) {
      let data = await this.commonGet2(`/community/${cid}/gas_list?m=${""}&p=${1}&sb=${""}&sd=${"0"}&pp=${""}`);
      data = data.result;
      if (data && data[0]) {
        let Gas = data[0].Total - data[0].InputCount;
        onUpdate({ Gas });
      }
    }

    // 意見未回覆
    if (panelsKeys.includes("Feedback")) {
      let data = await this.commonGet2(
        `/community/${cid}/feedback_list?p=${1}&sb=${""}&sd=${"0"}&pp=${""}&st=${""}&et=${""}&hr=${""}&b=${""}&d=${""}&f=${""}&t=${""}`,
      );
      data = data?.result?.filter((e) => !e.Reply);
      if (data) {
        let Feedback = data.length;
        onUpdate({ Feedback });
      }
    }
  }

  /** [智慧助理] 發送訊息
   * @param {string} msg 訊息內容
   * @param {string|null} id 會話ID
   */
  async sendMsgToBot(msg, id) {
    let user = store.getters.getUser;
    let cid = store.getters.getSelectCommunity.id;
    let url = `https://bheypo5vuh.execute-api.ap-northeast-1.amazonaws.com/prod/voice-bot?message=${msg}`;
    let res = await axios.post(url, {
      id,
      saveHistory: true, // 儲存會話歷史
      voiceOutput: false, // 輸出語音
      user: {
        cid,
        uid: user.id,
        uname: user.Name,
      },
    });
    let success = res?.status == 200;
    return success ? res.data : Promise.reject(res);
  }

  /** [郵務管理] 包裹標籤辨識(包裹速登)
   * @param {Blob} imgBlob 影像數據
   * @returns {Promise<{
   * name: string; // 收件人
   * phone: string; // 電話
   * address: string; // 收件地址
   * houseHold: string; // 戶別
   * }>} 包裹訊息
   */
  async packageLabelIdentific(imgBlob) {
    let url = `https://bheypo5vuh.execute-api.ap-northeast-1.amazonaws.com/prod/package-label-identific`;
    let formData = new FormData();
    formData.set("file", imgBlob);
    let res = await axios.post(url, formData);
    let success = res?.status == 200;
    return success ? res.data : Promise.reject(res);
  }

  // =============== CommonAPI =================

  login(username, password, cb_ok, cb_err) {
    this.commonGet(
      "/token",
      (data) => {
        let sha256 = require("js-sha256").sha256;
        let ans = {};
        ans["user"] = username;
        ans["token"] = data["Token"];
        ans["digest"] = sha256(`${username}:${data["Token"]}:${password}`);
        this.commonPost("/login", ans, cb_ok, cb_err);
      },
      cb_err,
    );
  }
  // CityDistricts
  getCityList(cb_ok, cb_err) {
    this.commonGet("/city_list", cb_ok, cb_err);
  }

  getDistrictList(city, cb_ok, cb_err) {
    this.commonGet(`/district_list?city=${city}`, cb_ok, cb_err);
  }

  // User

  getUser(cb_ok, cb_err) {
    this.commonGet("/user", cb_ok, cb_err);
  }

  /** 註冊用戶
   * @param {object} user       用戶資料
   * @param {string} code       簡訊驗證碼
   * @param {Function} cb_ok    成功回調
   * @param {Function} cb_err   異常回調
   */
  registerUser(user, code, cb_ok, cb_err) {
    this.commonPost_new(`/user?c=${code}`, user, cb_ok, cb_err);
  }

  /** 刪除用戶
   * @param {string} id         用戶編號
   * @param {Function} cb_ok    成功回調
   * @param {Function} cb_err   異常回調
   */
  deleteUser(id, cb_ok, cb_err) {
    this.commonDelete_new(`/user/${id}`, cb_ok, cb_err);
  }

  resetPassword(code, phone, password, cb_ok, cb_err) {
    this.commonPost(`/reset_password?c=${code}&m=${phone}&p=${password}`, null, cb_ok, cb_err);
  }

  // SMS
  /** 發送簡訊驗證碼
   * @param {string} phonenumber  手機號碼
   * @param {Function} cb_ok      成功回調
   * @param {Function} cb_err     異常回調
   */
  sendSMSCode(phonenumber, cb_ok, cb_err) {
    this.commonPost_new(`/sendsms?p=${phonenumber}`, null, cb_ok, cb_err);
  }

  sendResetSMSCode(phonenumber, cb_ok, cb_err) {
    this.commonPost(`/sendresetsms?p=${phonenumber}`, null, cb_ok, cb_err);
  }

  /** 獲取系統公告消息列表(物業前台) */
  getCommunitySystemBulletinList() {
    return this.commonGet2("/system_bulletin_list");
  }

  // =============== AdminAPI =================
  // SystemInfo
  getSystemInfo(cb_ok, cb_err) {
    this.commonGet("/admin/system_info", cb_ok, cb_err);
  }

  // System Notify
  sendSystemNotify(title, msg, cb_ok, cb_err) {
    this.commonPost(`/admin/system_notify?t=${title}&m=${msg}`, null, cb_ok, cb_err);
  }

  /** 獲取系統公告消息列表(管理後台) */
  getSystemBulletinList() {
    return this.commonGet2("/admin/system_bulletin_list");
  }

  /** 新增系統公告消息
   * @param item 項目資料
   */
  addSystemBulletin(item) {
    return this.commonPost2("/admin/system_bulletin", item);
  }

  /** 更新系統公告消息
   * @param item 項目資料
   */
  updateSystemBulletin(item) {
    return this.commonPut2("/admin/system_bulletin", item);
  }

  /** 刪除系統公告消息
   * @param {string} itemId 公告項目ID
   */
  deleteSystemBulletin(itemId) {
    return this.commonDelete2(`/admin/system_bulletin?itemId=${itemId}`);
  }

  // Community
  getCommunityList({ sortBy, sortDesc, page, itemsPerPage, searchText, dataType }, cb_ok, cb_err) {
    this.commonGet(`/admin/community_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&s=${searchText}&dt=${dataType}`, cb_ok, cb_err);
  }

  getCommunity(id, cb_ok, cb_err) {
    this.commonGet(`/admin/community/${id}`, cb_ok, cb_err);
  }

  addCommunity(data, cb_ok, cb_err) {
    this.commonPost("/admin/community", data, cb_ok, cb_err);
  }

  updateCommunity(data, cb_ok, cb_err) {
    this.commonPut("/admin/community", data, cb_ok, cb_err);
  }

  // Community Module
  addCommunityModule(cid, mid, cb_ok, cb_err) {
    this.commonPost(`/admin/community/${cid}/module/${mid}`, null, cb_ok, cb_err);
  }

  deleteCommunityModule(cid, mid, cb_ok, cb_err) {
    this.commonDelete(`/admin/community/${cid}/module/${mid}`, cb_ok, cb_err);
  }

  // deleteCommunity(id, cb_ok, cb_err) {
  //   this.commonDelete(`/admin/community/${id}`, cb_ok, cb_err);
  // }

  // Community Active Code
  getCommunityActiveCode(communityID, cb_ok, cb_err) {
    this.commonGet(`/admin/community/${communityID}/activecode`, cb_ok, cb_err);
  }

  verifyCommunityActiveCode(verifyCode, cb_ok, cb_err) {
    this.commonPost(`/verify_code?c=${verifyCode}`, null, cb_ok, cb_err);
  }

  // Household
  getCommunityHousehold(communityID, cb_ok, cb_err) {
    this.commonGet(`/admin/community/${communityID}/household`, cb_ok, cb_err);
  }

  updateCommunityHousehold(communityID, data, cb_ok, cb_err) {
    this.commonPut(`/admin/community/${communityID}/household`, data, cb_ok, cb_err);
  }

  // Community default role
  getCommunityDefaultRole(id, cb_ok, cb_err) {
    this.commonGet(`/admin/community_role/${id}`, cb_ok, cb_err);
  }

  getCommunityDefaultRoleList(cb_ok, cb_err) {
    this.commonGet("/admin/community_role_list", cb_ok, cb_err);
  }

  addCommunityDefaultRole(data, cb_ok, cb_err) {
    this.commonPost("/admin/community_role", data, cb_ok, cb_err);
  }

  updateCommunityDefaultRole(data, cb_ok, cb_err) {
    this.commonPut("/admin/community_role", data, cb_ok, cb_err);
  }

  deleteCommunityDefaultRole(id, cb_ok, cb_err) {
    this.commonDelete(`/admin/community_role/${id}`, cb_ok, cb_err);
  }

  // Community Admin User
  getCommunityAdminUserList({ communityID, sortBy, sortDesc, page, itemsPerPage, searchText }, cb_ok, cb_err) {
    this.commonGet(`/admin/community/${communityID}/adminuser_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&s=${searchText}`, cb_ok, cb_err);
  }
  updateCommunityAdminUser({ communityID, userID, roleID }, cb_ok, cb_err) {
    this.commonPut(`/admin/community/${communityID}/adminuser/${userID}/${roleID}`, "", cb_ok, cb_err);
  }

  /** 移除管理員帳戶權限 */
  deleteCommunityAdminUser({ communityID, userID }, cb_ok, cb_err) {
    this.commonDelete(`/admin/community/${communityID}/adminuser/${userID}`, cb_ok, cb_err);
  }

  getUserList_withoutCommunity({ sortBy, sortDesc, page, itemsPerPage, searchText, communityID }, cb_ok, cb_err) {
    this.commonGet(`/admin/community/user_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&s=${searchText}&wc=${communityID}`, cb_ok, cb_err);
  }

  // Community User
  getCommunityUserList({ sortBy, sortDesc, page, itemsPerPage, searchText }, cb_ok, cb_err) {
    this.commonGet(`/admin/community/user_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&s=${searchText}`, cb_ok, cb_err);
  }

  // Community default FacilityType
  getCommunityDefaultFacilityType(id, cb_ok, cb_err) {
    this.commonGet(`/admin/community_facilitytype/${id}`, cb_ok, cb_err);
  }

  getCommunityDefaultFacilityTypeList(cb_ok, cb_err) {
    this.commonGet("/admin/community_facilitytype_list", cb_ok, cb_err);
  }

  addCommunityDefaultFacilityType(data, cb_ok, cb_err) {
    this.commonPost("/admin/community_facilitytype", data, cb_ok, cb_err);
  }

  updateCommunityDefaultFacilityType(data, cb_ok, cb_err) {
    this.commonPut("/admin/community_facilitytype", data, cb_ok, cb_err);
  }

  deleteCommunityDefaultFacilityType(id, cb_ok, cb_err) {
    this.commonDelete(`/admin/community_facilitytype/${id}`, cb_ok, cb_err);
  }

  // Community default DepositType
  getCommunityDefaultDepositType(id, cb_ok, cb_err) {
    this.commonGet(`/admin/community_deposittype/${id}`, cb_ok, cb_err);
  }

  getCommunityDefaultDepositTypeList(cb_ok, cb_err) {
    this.commonGet("/admin/community_deposittype_list", cb_ok, cb_err);
  }

  addCommunityDefaultDepositType(data, cb_ok, cb_err) {
    this.commonPost("/admin/community_deposittype", data, cb_ok, cb_err);
  }

  updateCommunityDefaultDepositType(data, cb_ok, cb_err) {
    this.commonPut("/admin/community_deposittype", data, cb_ok, cb_err);
  }

  deleteCommunityDefaultDepositType(id, cb_ok, cb_err) {
    this.commonDelete(`/admin/community_deposittype/${id}`, cb_ok, cb_err);
  }

  // Community default PackageType
  getCommunityDefaultPackageType(id, cb_ok, cb_err) {
    this.commonGet(`/admin/community_packagetype/${id}`, cb_ok, cb_err);
  }

  getCommunityDefaultPackageTypeList(cb_ok, cb_err) {
    this.commonGet("/admin/community_packagetype_list", cb_ok, cb_err);
  }

  addCommunityDefaultPackageType(data, cb_ok, cb_err) {
    this.commonPost("/admin/community_packagetype", data, cb_ok, cb_err);
  }

  updateCommunityDefaultPackageType(data, cb_ok, cb_err) {
    this.commonPut("/admin/community_packagetype", data, cb_ok, cb_err);
  }

  deleteCommunityDefaultPackageType(id, cb_ok, cb_err) {
    this.commonDelete(`/admin/community_packagetype/${id}`, cb_ok, cb_err);
  }

  // Community default Shipping from
  getCommunityDefaultShippingFrom(id, cb_ok, cb_err) {
    this.commonGet(`/admin/community_shippingfrom/${id}`, cb_ok, cb_err);
  }

  getCommunityDefaultShippingFromList(cb_ok, cb_err) {
    this.commonGet("/admin/community_shippingfrom_list", cb_ok, cb_err);
  }

  addCommunityDefaultShippingFrom(data, cb_ok, cb_err) {
    this.commonPost("/admin/community_shippingfrom", data, cb_ok, cb_err);
  }

  updateCommunityDefaultShippingFrom(data, cb_ok, cb_err) {
    this.commonPut("/admin/community_shippingfrom", data, cb_ok, cb_err);
  }

  deleteCommunityDefaultShippingFrom(id, cb_ok, cb_err) {
    this.commonDelete(`/admin/community_shippingfrom/${id}`, cb_ok, cb_err);
  }

  // Community default Shipping provider
  getCommunityDefaultShippingProvider(id, cb_ok, cb_err) {
    this.commonGet(`/admin/community_shippingprovider/${id}`, cb_ok, cb_err);
  }

  getCommunityDefaultShippingProviderList(cb_ok, cb_err) {
    this.commonGet("/admin/community_shippingprovider_list", cb_ok, cb_err);
  }

  addCommunityDefaultShippingProvider(data, cb_ok, cb_err) {
    this.commonPost("/admin/community_shippingprovider", data, cb_ok, cb_err);
  }

  updateCommunityDefaultShippingProvider(data, cb_ok, cb_err) {
    this.commonPut("/admin/community_shippingprovider", data, cb_ok, cb_err);
  }

  deleteCommunityDefaultShippingProvider(id, cb_ok, cb_err) {
    this.commonDelete(`/admin/community_shippingprovider/${id}`, cb_ok, cb_err);
  }

  // Community default visit reason
  getCommunityDefaultVisitReason(id, cb_ok, cb_err) {
    this.commonGet(`/admin/community_visitreason/${id}`, cb_ok, cb_err);
  }

  getCommunityDefaultVisitReasonList(cb_ok, cb_err) {
    this.commonGet("/admin/community_visitreason_list", cb_ok, cb_err);
  }

  addCommunityDefaultVisitReason(data, cb_ok, cb_err) {
    this.commonPost("/admin/community_visitreason", data, cb_ok, cb_err);
  }

  updateCommunityDefaultVisitReason(data, cb_ok, cb_err) {
    this.commonPut("/admin/community_visitreason", data, cb_ok, cb_err);
  }

  deleteCommunityDefaultVisitReason(id, cb_ok, cb_err) {
    this.commonDelete(`/admin/community_visitreason/${id}`, cb_ok, cb_err);
  }

  // Community default company
  getCommunityDefaultCompany(id, cb_ok, cb_err) {
    this.commonGet(`/admin/community_company/${id}`, cb_ok, cb_err);
  }

  getCommunityDefaultCompanyList(cb_ok, cb_err) {
    this.commonGet("/admin/community_company_list", cb_ok, cb_err);
  }

  addCommunityDefaultCompany(data, cb_ok, cb_err) {
    this.commonPost("/admin/community_company", data, cb_ok, cb_err);
  }

  updateCommunityDefaultCompany(data, cb_ok, cb_err) {
    this.commonPut("/admin/community_company", data, cb_ok, cb_err);
  }

  deleteCommunityDefaultCompany(id, cb_ok, cb_err) {
    this.commonDelete(`/admin/community_company/${id}`, cb_ok, cb_err);
  }

  // Community Permission

  /** [社區管理] 獲取權限列表 */
  getCommunityPermissionList() {
    return this.commonGet2("/admin/community_permission_list");
  }

  addCommunityPermission(data, cb_ok, cb_err) {
    this.commonPost("/admin/community_permission", data, cb_ok, cb_err);
  }

  updateCommunityPermission(data, cb_ok, cb_err) {
    this.commonPut("/admin/community_permission", data, cb_ok, cb_err);
  }

  deleteCommunityPermission(id, cb_ok, cb_err) {
    this.commonDelete(`/admin/community_permission/${id}`, cb_ok, cb_err);
  }

  // Permission
  getPermissionList(cb_ok, cb_err) {
    this.commonGet("/admin/permission_list", cb_ok, cb_err);
  }

  addPermission(data, cb_ok, cb_err) {
    this.commonPost("/admin/permission", data, cb_ok, cb_err);
  }

  updatePermission(data, cb_ok, cb_err) {
    this.commonPut("/admin/permission", data, cb_ok, cb_err);
  }

  deletePermission(id, cb_ok, cb_err) {
    this.commonDelete(`/admin/permission/${id}`, cb_ok, cb_err);
  }

  // Role
  getRole_AdminPlatfom(id, cb_ok, cb_err) {
    this.commonGet(`/admin/role/${id}`, cb_ok, cb_err);
  }

  getRoleList_AdminPlatfom(cb_ok, cb_err) {
    this.commonGet("/admin/role_list", cb_ok, cb_err);
  }

  addRole_AdminPlatfom(data, cb_ok, cb_err) {
    this.commonPost("/admin/role", data, cb_ok, cb_err);
  }

  updateRole_AdminPlatfom(data, cb_ok, cb_err) {
    this.commonPut("/admin/role", data, cb_ok, cb_err);
  }

  deleteRole_AdminPlatfom(id, cb_ok, cb_err) {
    this.commonDelete(`/admin/role/${id}`, cb_ok, cb_err);
  }

  // User
  getUser_AdminPlatfom(id, cb_ok, cb_err) {
    this.commonGet(`/admin/user/${id}`, cb_ok, cb_err);
  }

  getUserList_AdminPlatfom({ sortBy, sortDesc, page, itemsPerPage, searchText }, cb_ok, cb_err) {
    this.commonGet(`/admin/user_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&s=${searchText}`, cb_ok, cb_err);
  }

  addUser_AdminPlatfom(data, cb_ok, cb_err) {
    this.commonPost("/admin/user", data, cb_ok, cb_err);
  }

  updateUser_AdminPlatfom(data, cb_ok, cb_err) {
    this.commonPut("/admin/user", data, cb_ok, cb_err);
  }

  /** 刪除管理者帳戶 */
  deleteUser_AdminPlatfom(id, cb_ok, cb_err) {
    this.commonDelete(`/admin/user/${id}`, cb_ok, cb_err);
  }

  // Module
  getModuleList(cb_ok, cb_err) {
    this.commonGet("/admin/module_list", cb_ok, cb_err);
  }

  addModule(data, cb_ok, cb_err) {
    this.commonPost("/admin/module", data, cb_ok, cb_err);
  }

  updateModule(data, cb_ok, cb_err) {
    this.commonPut("/admin/module", data, cb_ok, cb_err);
  }

  deleteModule(id, cb_ok, cb_err) {
    this.commonDelete(`/admin/module/${id}`, cb_ok, cb_err);
  }

  // sinopac pay setting
  getCommunitySinopacPaySetting(communityID, cb_ok, cb_err) {
    this.commonGet(`/admin/community/${communityID}/pay/sinopac/setting`, cb_ok, cb_err);
  }

  updateCommunitySinopacPaySetting(communityID, setting, cb_ok, cb_err) {
    this.commonPut(`/admin/community/${communityID}/pay/sinopac/setting`, setting, cb_ok, cb_err);
  }

  // local server auth
  getLocalServerAuth(communityID, cb_ok, cb_err) {
    this.commonGet(`/admin/community/${communityID}/local/auth`, cb_ok, cb_err);
  }

  updateLocalServerAuth(communityID, cb_ok, cb_err) {
    this.commonPut(`/admin/community/${communityID}/local/update_auth`, null, cb_ok, cb_err);
  }

  // third party service
  getServiceList({ sortBy, sortDesc, page, itemsPerPage, searchText }, cb_ok, cb_err) {
    this.commonGet(`/admin/service_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&s=${searchText}`, cb_ok, cb_err);
  }

  getService(id, cb_ok, cb_err) {
    this.commonGet(`/admin/service/${id}`, cb_ok, cb_err);
  }

  addService(data, cb_ok, cb_err) {
    this.commonPost("/admin/service", data, cb_ok, cb_err);
  }

  updateService(data, cb_ok, cb_err) {
    this.commonPut("/admin/service", data, cb_ok, cb_err);
  }

  // intercom
  getServiceIntercomStationList(sid, cb_ok, cb_err) {
    this.commonGet(`/admin/service/${sid}/intercom_list`, cb_ok, cb_err);
  }

  addServiceIntercomStation(sid, data, cb_ok, cb_err) {
    this.commonPost(`/admin/service/${sid}/intercom`, data, cb_ok, cb_err);
  }

  updateServiceIntercomStation(sid, data, cb_ok, cb_err) {
    this.commonPut(`/admin/service/${sid}/intercom`, data, cb_ok, cb_err);
  }

  deleteServiceIntercomStation(sid, isID, cb_ok, cb_err) {
    this.commonDelete(`/admin/service/${sid}/intercom/${isID}`, cb_ok, cb_err);
  }

  // =============== CommunityAPI =================

  /** 註冊裝置 FCM 資訊 */
  async registerDeviceInfo(token) {
    const cid = store.getters.getSelectCommunity.id;
    const user = store.getters.getUser;

    // 裝置類型後輟
    let typeSuffix = "";
    if (store.getters.isSpeaker) {
      typeSuffix = "(VoiceBox)";
    } else if (store.getters.isMobile) {
      typeSuffix = "(Mobile)";
    }

    let data = {
      uid: store.state.uuid,
      fcm: token,
      type: `Web${typeSuffix}`,
      user: user.id,
    };

    let res = await this.commonPost2(`/community/${cid}/device_info`, data);
    window.localStorage?.setItem("deviceInfoId", res.ID);
    return Promise.resolve();
  }

  /** 移除裝置資訊 */
  removeDeviceInfo() {
    const did = window.localStorage.getItem("deviceInfoId");
    if (!did) return Promise.reject("no deviceInfoId !");
    const cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/device_info/remove`, { did });
  }

  getCommunityInfo(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/info`, cb_ok, cb_err);
  }

  getCommunityCountData(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/info_count`, cb_ok, cb_err);
  }

  // ======================= 郵務管理相關 =======================

  /** [郵務管理] 獲取包裹編號 */
  generatePackageID() {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/generate_package_id`);
  }

  /** [郵務管理] 釋出包裹編號
   * @param {string|string[]} pid 包裹編號
   */
  releasePackageID(pid) {
    pid = pid instanceof Array ? pid.join(",") : pid;
    let cid = store.getters.getSelectCommunity.id;
    return this.commonDelete2(`/community/${cid}/release_package_id?id=${pid}`);
  }

  /** [郵務管理] 儲存包裹(通知用戶) */
  savePackage(data) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/package`, data);
  }

  /** [郵務管理] 上傳登記圖像
   * @param {{
   * id: string; // 包裹編號
   * img: Blob; // 包裹影像
   * }[]} data 檔案資料
   * @example
   * 回傳的類型為<包裹編號:圖像路徑>的字典(物件)類型，例:
   * {
   *    103:"/xxx/xxx.png",
   *    104:"/xxx/xxx.png",
   *    ...
   * }
   */
  uploadPackageImg(data) {
    var formData = new FormData();
    data.forEach((e) => formData.append(e.id, e.img, "img.jpg"));
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/package_upload_img`, formData);
  }

  /** [郵務管理] 刪除包裹
   * @param {string} pid 包裹編號
   */
  deletePackage(pid) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonDelete2(`/community/${cid}/package/${pid}`);
  }

  updatePackageToWaitReturn(cid, pid, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/package_to_wait_return/${pid}`, null, cb_ok, cb_err);
  }

  updatePackageToReturn(cid, pid, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/package_to_return/${pid}`, null, cb_ok, cb_err);
  }

  getPackageList_History(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/package_list?s=2`, cb_ok, cb_err);
  }

  /** [郵務管理] 獲取包裹列表 */
  getPackageList(
    cid,
    {
      sortBy,
      sortDesc,
      page,
      itemsPerPage,
      status,
      startDate,
      endDate,
      startDate_check,
      endDate_check,
      packageType,
      building,
      doorPlate,
      floor,
      checkMethod,
      barcode,
      packageTypeCustomName,
      packageCash,
    },
    cb_ok,
    cb_err,
  ) {
    if (!startDate_check) startDate_check = "";
    if (!endDate_check) endDate_check = "";
    if (!startDate) startDate = "";
    if (!endDate) endDate = "";
    if (!packageType) packageType = "";
    if (!building) building = "";
    if (!doorPlate) doorPlate = "";
    if (!floor) floor = "";
    if (!checkMethod) checkMethod = "";
    if (!barcode) barcode = "";
    if (!packageTypeCustomName) packageTypeCustomName = "";
    if (!packageCash) packageCash = "";

    this.commonGet(
      `/community/${cid}/package_list?s=${status}&p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&st=${startDate}&et=${endDate}&stc=${startDate_check}&etc=${endDate_check}&pt=${packageType}&b=${building}&d=${doorPlate}&f=${floor}&cm=${checkMethod}&bc=${barcode}&ptcn=${packageTypeCustomName}&pc=${packageCash}`,
      cb_ok,
      cb_err,
    );
  }

  getPackageList_WaitReceive_ByDeviceDBID(cid, deviceID, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/package_list?s=2&did=${deviceID}`, cb_ok, cb_err);
  }

  getPackageList_WaitReceive_ByCardUID(cid, cardUID, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/package_list?s=2&c=${cardUID}`, cb_ok, cb_err);
  }

  getPackageList_WaitReceive_ByHouseHold(cid, { building, doorPlate, floor }, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/package_list?s=2&b=${building}&d=${doorPlate}&f=${floor}`, cb_ok, cb_err);
  }

  /** [郵務管理] 更新包裹狀態 */
  updatePackage({ User, Package, Status, CheckMethod, DeviceDBID, CardUID, CheckPS, Image, SignPhoto }) {
    var formData = new FormData();
    if (User) formData.set("User", User);
    formData.set("Package", Package);
    formData.set("Status", Status);
    formData.set("CheckMethod", CheckMethod);
    if (DeviceDBID) formData.set("DeviceDBID", DeviceDBID);
    if (CardUID) formData.set("CardUID", CardUID);
    if (CheckPS) formData.set("CheckPS", CheckPS);
    if (Image) formData.set("Image", Image, "webcam.jpg");
    if (SignPhoto) formData.set("SignPhoto", SignPhoto, "signature.png");
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPut2(`/community/${cid}/package`, formData);
  }

  packageNotifyAgain(cid, packageList, cb_ok, cb_err) {
    let listString = packageList.join(",");
    this.commonPost(`/community/${cid}/package_notify_again?p=${listString}`, null, cb_ok, cb_err);
  }

  /** [包裹放置] 獲取地點列表 */
  getPackagePlaceList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/package_place_list`, cb_ok, cb_err);
  }

  /** [包裹放置] 新增地點 */
  addPackagePlace(cid, placeName, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/package_place?p=${placeName}`, null, cb_ok, cb_err);
  }

  /** [包裹放置] 更新地點 */
  updatePackagePlace(cid, places, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/package_place`, places, cb_ok, cb_err);
  }

  /** [包裹放置] 刪除地點 */
  deletePackagePlace(cid, placeName, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/package_place?p=${placeName}`, cb_ok, cb_err);
  }

  // WebCam
  // getWebCamPhoto(cid, fileName, cb_ok, cb_err) {
  //   this.commonGet(`/community/${cid}/webcam?f=${fileName}`, cb_ok, cb_err);
  // }

  // getPackageWebCamPhotoUrl(cid, fileName) {
  //   return `${this.url}/community/${cid}/package_webcam?f=${fileName}`;
  // }

  // getGuestWebCamPhotoUrl(cid, fileName) {
  //   return `${this.url}/community/${cid}/guest_webcam?f=${fileName}`;
  // }

  /** 獲取檔案 URL
   * @param {string} filePath 檔案路徑
   * @returns {string} 完整的檔案路徑
   */
  getFileUrl(filePath) {
    let cid = store.getters.getSelectCommunity.id;
    let token = store.getters.getToken;
    return `${this.url}/community/${cid}/file?f=${filePath}&token=${token}`;
  }

  /** 獲取檔案 Blob
   * @param {string} filePath 檔案路徑
   * @returns {Promise<Blob>} 檔案 Blob
   */
  getFileBlob(filePath) {
    let cid = store.getters.getSelectCommunity.id;
    let token = store.getters.getToken;
    return this.commonGet2(`/community/${cid}/file?f=${filePath}&token=${token}`, {
      responseType: "blob",
    });
  }

  // GuestBorrowItem
  getGuestBorrowItemList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/guest_borrow_item_list`, cb_ok, cb_err);
  }

  addGuestBorrowItem(cid, itemName, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/guest_borrow_item?i=${itemName}`, null, cb_ok, cb_err);
  }

  updateGuestBorrowItem(cid, items, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/guest_borrow_item`, items, cb_ok, cb_err);
  }

  deleteGuestBorrowItem(cid, itemName, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/guest_borrow_item?i=${itemName}`, cb_ok, cb_err);
  }

  // GuestVisitPlace
  getGuestVisitPlaceList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/guest_visit_place_list`, cb_ok, cb_err);
  }

  addGuestVisitPlace(cid, placeName, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/guest_visit_place?p=${placeName}`, null, cb_ok, cb_err);
  }

  updateGuestVisitPlace(cid, places, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/guest_visit_place`, places, cb_ok, cb_err);
  }

  deleteGuestVisitPlace(cid, placeName, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/guest_visit_place?p=${placeName}`, cb_ok, cb_err);
  }

  // Guest
  addGuest(cid, GuestData, Image, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(GuestData));
    if (Image) formData.set("Image", Image, "webcam.jpg");
    this.commonPost(`/community/${cid}/guest`, formData, cb_ok, cb_err);
  }

  getGuestList_ByStatus(cid, guestStatus, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/guest_list?s=${guestStatus}`, cb_ok, cb_err);
  }

  getGuestList(
    cid,
    { sortBy, sortDesc, page, itemsPerPage, status, startDate, endDate, startDate_check, endDate_check, building, doorPlate, floor, visitPlace },
    cb_ok,
    cb_err,
  ) {
    if (!startDate_check) startDate_check = "";
    if (!endDate_check) endDate_check = "";
    if (!startDate) startDate = "";
    if (!endDate) endDate = "";
    if (!building) building = "";
    if (!doorPlate) doorPlate = "";
    if (!floor) floor = "";
    if (!visitPlace) visitPlace = "";

    this.commonGet(
      `/community/${cid}/guest_list?s=${status}&p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&st=${startDate}&et=${endDate}&stc=${startDate_check}&etc=${endDate_check}&b=${building}&d=${doorPlate}&f=${floor}&vp=${visitPlace}`,
      cb_ok,
      cb_err,
    );
  }

  updateGuestToLeave(cid, guestID, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/guest_leave/${guestID}`, null, cb_ok, cb_err);
  }

  // Card
  // getCardInfoListInHouseHold(cid, { building, doorPlate, floor }, cb_ok, cb_err) {
  //   this.commonGet(`/community/${cid}/card_list?b=${building}&d=${doorPlate}&f=${floor}`, cb_ok, cb_err);
  // }
  deleteHouseHoldCard(cid, { building, doorPlate, floor, cardID }, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/card?b=${building}&d=${doorPlate}&f=${floor}&c=${cardID}`, cb_ok, cb_err);
  }

  // Open Service
  getOpenServiceList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/open_service_list`, cb_ok, cb_err);
  }

  openService(cid, { building, doorPlate, floor, deviceID, cardUID }, cb_ok, cb_err) {
    let DeviceUID = "";
    let CardUID = "";
    if (deviceID) DeviceUID = deviceID;
    if (cardUID) CardUID = cardUID;

    this.commonPut(`/community/${cid}/open_service?b=${building}&d=${doorPlate}&f=${floor}&i=${DeviceUID}&c=${CardUID}`, null, cb_ok, cb_err);
  }

  /** 獲取用戶開通序號
   * @param {string} cid          社區編號
   * @param {object} houseHold    戶別
   * @param {Function} cb_ok      成功回調
   * @param {Function} cb_err     異常回調
   */
  getUserActiveCode(cid, { building, doorPlate, floor }, cb_ok, cb_err) {
    this.commonGet_new(`/community/${cid}/open_service/activecode?b=${building}&d=${doorPlate}&f=${floor}`, cb_ok, cb_err);
  }

  deleteHouseHoldUser(cid, { building, doorPlate, floor, userID }, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/open_service?b=${building}&d=${doorPlate}&f=${floor}&u=${userID}`, cb_ok, cb_err);
  }

  // HouseHold
  getHouseHold(cid, { building, doorPlate, floor }, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/household?b=${building}&d=${doorPlate}&f=${floor}`, cb_ok, cb_err);
  }

  updateHouseHoldRentStatus(cid, { building, doorPlate, floor }, isRent, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/household/rent?b=${building}&d=${doorPlate}&f=${floor}&s=${isRent ? "1" : "0"}`, null, cb_ok, cb_err);
  }

  getHouseHoldListHasDeviceDBID(cid, dbid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/household_list?did=${dbid}`, cb_ok, cb_err);
  }

  getHouseHoldListHasCard(cid, cardID, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/household_list?c=${cardID}`, cb_ok, cb_err);
  }

  // HouseHold Detail
  getHouseHoldDetailList(cid, { building, doorPlate, floor }, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/household/detail_list?b=${building}&d=${doorPlate}&f=${floor}`, cb_ok, cb_err);
  }

  addHouseHoldDetail(cid, detailObj, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/household/detail`, detailObj, cb_ok, cb_err);
  }

  updateHouseHoldDetail(cid, detailObj, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/household/detail`, detailObj, cb_ok, cb_err);
  }

  deleteHouseHoldDetail(cid, did, cb_ok, cb_err) {
    5;
    this.commonDelete(`/community/${cid}/household/detail/${did}`, cb_ok, cb_err);
  }

  getHouseHoldDetailXlsFileUrl(cid) {
    return `${this.url}/community/${cid}/household/detail_export`;
  }

  // Notification
  /**
   * [推播通知] 新增通知
   * @param {*} houseHoldList 戶別列表
   * @param {string} message 訊息
   * @param {File} files 檔案列表
   * @param {Blob} recorded 留言音檔
   * @param {boolean} urgent 緊急通知
   */
  addNotification(houseHoldList, message, files, recorded, urgent) {
    var formData = new FormData();
    formData.set("Household", JSON.stringify(houseHoldList));
    files?.forEach((file) => formData.append("Files", file));
    if (message) formData.set("Message", message);
    if (recorded) {
      let file = new File([recorded], "msg.wav", {
        type: recorded.type,
        lastModified: Date.now(),
      });
      formData.set("Voice", file);
    }
    formData.set("Urgent", urgent);

    const cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/notification`, formData);
  }

  /**
   * [推播通知] 獲取記錄列表
   * @param {*} param1 傳入參數
   */
  getNotificationList({ sortBy, sortDesc, page, itemsPerPage, startDate, endDate }) {
    if (!startDate) startDate = "";
    if (!endDate) endDate = "";
    const cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/notification_list?&p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&st=${startDate}&et=${endDate}`);
  }

  /** [推播通知] 再次發送通知
   * @param {string} nid 通知消息ID
   */
  NotificationUnreadNotifyAgain(nid) {
    const cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/notification_unread_notify_again/${nid}`);
  }

  // Notification Type

  /** 獲取推播類型列表
   * @param {string} cid 社區ID
   */
  getNotificationTypeItemList(cid) {
    return this.commonGet2(`/community/${cid}/notification_type_list`);
  }

  /** 新增推播類型
   * @param {string} cid 社區ID
   * @param {string} itemName 類型名稱
   */
  addNotificationTypeItem(cid, itemName) {
    return this.commonPost2(`/community/${cid}/notification_type?t=${itemName}`);
  }

  /** 更新推播類型列表
   * @param {string} cid 社區ID
   * @param {*} items 項目列表
   */
  updateNotificationTypeItem(cid, items) {
    return this.commonPut2(`/community/${cid}/notification_type`, items);
  }

  /** 刪除推播類型
   * @param {string} cid 社區ID
   * @param {string} itemName 項目名稱
   */
  deleteNotificationTypeItem(cid, itemName) {
    return this.commonDelete2(`/community/${cid}/notification_type?t=${itemName}`);
  }

  // ======================= 寄放物品相關 =======================

  // Deposit
  saveDepositPackage(cid, depositData, image, cb_ok, cb_err) {
    var formData = new FormData();

    formData.set("Data", JSON.stringify(depositData));
    if (image) formData.set("Image", image, "webcam.jpg");

    this.commonPost(`/community/${cid}/deposit`, formData, cb_ok, cb_err);
  }

  /** [寄放物品] 更新包裹 */
  updateDepositPackage({ User, Package, Status, CheckMethod, DeviceDBID, CardUID, CheckPS, Image, SignPhoto }) {
    var formData = new FormData();
    if (User) formData.set("User", User);
    formData.set("Package", Package);
    formData.set("Status", Status);
    formData.set("CheckMethod", CheckMethod);
    if (DeviceDBID) formData.set("DeviceDBID", DeviceDBID);
    if (CardUID) formData.set("CardUID", CardUID);
    if (CheckPS) formData.set("CheckPS", CheckPS);
    if (Image) formData.set("Image", Image, "webcam.jpg");
    if (SignPhoto) formData.set("SignPhoto", SignPhoto, "signature.png");

    let cid = store.getters.getSelectCommunity.id;
    return this.commonPut2(`/community/${cid}/deposit`, formData);
  }

  getPackageList_Deposit_WaitReceive_ByDeviceDBID(cid, deviceID, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/package_list?s=6&did=${deviceID}`, cb_ok, cb_err);
  }

  getPackageList_Deposit_WaitReceive_ByCardUID(cid, cardUID, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/package_list?s=6&c=${cardUID}`, cb_ok, cb_err);
  }

  getPackageList_Deposit_WaitReceive_ByHouseHold(cid, { building, doorPlate, floor }, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/package_list?s=6&b=${building}&d=${doorPlate}&f=${floor}`, cb_ok, cb_err);
  }

  /** [寄放管理] 獲取寄放地點列表 */
  getDepositPlaceList() {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/deposit_place_list`);
  }

  /** [寄放管理] 新增寄放地點
   * @param {string} placeName 地點名稱
   */
  addDepositPlace(placeName) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/deposit_place?p=${placeName}`);
  }

  /** [寄放管理] 更新寄放地點(排序)
   * @param {string[]} places 地點列表
   */
  updateDepositPlace(places) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPut2(`/community/${cid}/deposit_place`, places);
  }

  /** [寄放管理] 刪除寄放地點
   * @param {string} placeName 地點名稱
   */
  deleteDepositPlace(placeName) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonDelete2(`/community/${cid}/deposit_place?p=${placeName}`);
  }

  // Point
  getPoint(cid, { building, doorPlate, floor }, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/point?b=${building}&d=${doorPlate}&f=${floor}`, cb_ok, cb_err);
  }
  updatePoint(cid, pch, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/point`, pch, cb_ok, cb_err);
  }

  getPointHistory(cid, { sortBy, sortDesc, page, itemsPerPage, startDate, endDate, building, doorPlate, floor, pointType, changeMethod }, cb_ok, cb_err) {
    if (!startDate) startDate = "";
    if (!endDate) endDate = "";
    if (!building) building = "";
    if (!doorPlate) doorPlate = "";
    if (!floor) floor = "";
    if (!pointType) pointType = "";
    if (!changeMethod) changeMethod = "";

    this.commonGet(
      `/community/${cid}/point_history?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&st=${startDate}&et=${endDate}&b=${building}&d=${doorPlate}&f=${floor}&pt=${pointType}&cm=${changeMethod}`,
      cb_ok,
      cb_err,
    );
  }

  getPointList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/point_list`, cb_ok, cb_err);
  }

  exportPointList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/point_export`, cb_ok, cb_err);
  }

  getPointXlsFileUrl(cid) {
    return `${this.url}/community/${cid}/point_export`;
  }

  importPointList(cid, xlsxFile, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Xlsx", xlsxFile);
    this.commonPost(`/community/${cid}/point_import`, formData, cb_ok, cb_err);
  }

  // Point Item Type
  getPointItemTypeList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/point_item_type_list`, cb_ok, cb_err);
  }

  addPointItemType(cid, newItem, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/point_item_type`, newItem, cb_ok, cb_err);
  }

  updatePointItemType(cid, itemList, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/point_item_type`, itemList, cb_ok, cb_err);
  }

  deletePointItemType(cid, pitID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/point_item_type/${pitID}`, cb_ok, cb_err);
  }

  // Point Item
  getPointItemList_All(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/point_item_list?itid=all`, cb_ok, cb_err);
  }

  getPointItemList(cid, itemTypeID, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/point_item_list?itid=${itemTypeID}`, cb_ok, cb_err);
  }

  addPointItem(cid, newItem, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/point_item`, newItem, cb_ok, cb_err);
  }

  updatePointItem(cid, itemList, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/point_item`, itemList, cb_ok, cb_err);
  }

  deletePointItem(cid, itID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/point_item/${itID}`, cb_ok, cb_err);
  }

  // Facility
  getFacilityList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/facility_list`, cb_ok, cb_err);
  }

  addFacility(cid, facilityData, images, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(facilityData));
    if (images) {
      images.forEach((img) => {
        formData.append("Image", img);
      });
    }
    this.commonPost(`/community/${cid}/facility`, formData, cb_ok, cb_err);
  }

  updateFacility(cid, facilityData, images, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(facilityData));

    if (images) {
      images.forEach((img) => {
        formData.append("Image", img);
      });
    }
    this.commonPut(`/community/${cid}/facility`, formData, cb_ok, cb_err);
  }

  deleteFacility(cid, fID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/facility/${fID}`, cb_ok, cb_err);
  }

  getFacilityAvailableBookingTime(cid, fID, startDate, startTime, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/facility/${fID}/available_booking_time?sd=${startDate}&st=${startTime}`, cb_ok, cb_err);
  }

  getFacilityUseDetail(cid, fID, date, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/facility/${fID}/use_detail?d=${date}`, cb_ok, cb_err);
  }

  getFacilityUseCount(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/facility_use_count`, cb_ok, cb_err);
  }

  // Booking Facility
  bookingFacility(cid, fID, bookingData, startDate, startTime, endTime, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/facility/${fID}/booking?sd=${startDate}&st=${startTime}&et=${endTime}`, bookingData, cb_ok, cb_err);
  }

  getFacilityBookingList(
    cid,
    {
      sortBy,
      sortDesc,
      page,
      itemsPerPage,
      facility,
      building,
      doorPlate,
      floor,
      startCheckDate,
      endCheckDate,
      startBookingDate,
      endBookingDate,
      startEnterDate,
      endEnterDate,
      startLeaveDate,
      endLeaveDate,
      status,
    },
    cb_ok,
    cb_err,
  ) {
    if (!facility) facility = "";
    if (!building) building = "";
    if (!doorPlate) doorPlate = "";
    if (!floor) floor = "";
    if (!startCheckDate) startCheckDate = "";
    if (!endCheckDate) endCheckDate = "";
    if (!startBookingDate) startBookingDate = "";
    if (!endBookingDate) endBookingDate = "";

    if (!startEnterDate) startEnterDate = "";
    if (!endEnterDate) endEnterDate = "";
    if (!startLeaveDate) startLeaveDate = "";
    if (!endLeaveDate) endLeaveDate = "";

    if (!status) status = "";

    this.commonGet(
      `/community/${cid}/facility_booking_list?&p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&fa=${facility}&b=${building}&d=${doorPlate}&f=${floor}&scd=${startCheckDate}&ecd=${endCheckDate}&sbd=${startBookingDate}&ebd=${endBookingDate}&sed=${startEnterDate}&eed=${endEnterDate}&sld=${startLeaveDate}&eld=${endLeaveDate}&s=${status}`,
      cb_ok,
      cb_err,
    );
  }

  cancelFacilityBook(cid, fID, bID, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/facility/${fID}/booking/${bID}/cancel`, null, cb_ok, cb_err);
  }

  // Enter Facility
  enterFacility(cid, fID, bookingDataID, bookingData, startTime, endTime, cb_ok, cb_err) {
    if (!bookingDataID) bookingDataID = "";
    if (!startTime) startTime = "";
    if (!endTime) endTime = "";
    this.commonPost(`/community/${cid}/facility/${fID}/enter?bdid=${bookingDataID}&st=${startTime}&et=${endTime}`, bookingData, cb_ok, cb_err);
  }

  // Leave Facility
  leaveFacility(cid, fID, bID, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/facility/${fID}/booking/${bID}/leave`, null, cb_ok, cb_err);
  }

  // FacilityBorrowItem
  getFacilityBorrowItemList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/facility_borrow_item_list`, cb_ok, cb_err);
  }

  addFacilityBorrowItem(cid, itemName, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/facility_borrow_item?i=${itemName}`, null, cb_ok, cb_err);
  }

  updateFacilityBorrowItem(cid, items, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/facility_borrow_item`, items, cb_ok, cb_err);
  }

  deleteFacilityBorrowItem(cid, itemName, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/facility_borrow_item?i=${itemName}`, cb_ok, cb_err);
  }

  // News
  addNews(cid, startDate, endDate, newsData, files, images, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(newsData));
    if (files) {
      files.forEach((f) => {
        formData.append("File", f);
      });
    }
    if (images) {
      images.forEach((i) => {
        formData.append("Image", i);
      });
    }
    if (!startDate) startDate = "";
    if (!endDate) endDate = "";

    this.commonPost(`/community/${cid}/news?st=${startDate}&et=${endDate}`, formData, cb_ok, cb_err);
  }

  updateNews(cid, startDate, endDate, newsData, files, images, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(newsData));
    if (files) {
      files.forEach((f) => {
        formData.append("File", f);
      });
    }
    if (images) {
      images.forEach((i) => {
        formData.append("Image", i);
      });
    }
    if (!startDate) startDate = "";
    if (!endDate) endDate = "";

    this.commonPut(`/community/${cid}/news?st=${startDate}&et=${endDate}`, formData, cb_ok, cb_err);
  }

  getNewsList(cid, { sortBy, sortDesc, page, itemsPerPage, startDate, endDate, status, type, search }, cb_ok, cb_err) {
    if (!startDate) startDate = "";
    if (!endDate) endDate = "";
    if (!status) status = "";
    if (!type) type = "";
    if (!search) search = "";

    this.commonGet(
      `/community/${cid}/news_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&st=${startDate}&et=${endDate}&s=${status}&t=${type}&ss=${search}`,
      cb_ok,
      cb_err,
    );
  }

  deleteNews(cid, nID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/news/${nID}`, cb_ok, cb_err);
  }

  // News Type
  getNewsTypeItemList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/news_type_list`, cb_ok, cb_err);
  }

  addNewsTypeItem(cid, itemName, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/news_type?t=${itemName}`, null, cb_ok, cb_err);
  }

  updateNewsTypeItem(cid, items, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/news_type`, items, cb_ok, cb_err);
  }

  deleteNewsTypeItem(cid, itemName, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/news_type?t=${itemName}`, cb_ok, cb_err);
  }

  // Feedback
  getFeedbackList(cid, { sortBy, sortDesc, page, itemsPerPage, startDate, endDate, hasRead, type, building, doorPlate, floor }, cb_ok, cb_err) {
    if (!startDate) startDate = "";
    if (!endDate) endDate = "";
    if (!hasRead) hasRead = "";
    if (!type) type = "";
    if (!building) building = "";
    if (!doorPlate) doorPlate = "";
    if (!floor) floor = "";

    this.commonGet(
      `/community/${cid}/feedback_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&st=${startDate}&et=${endDate}&hr=${hasRead}&b=${building}&d=${doorPlate}&f=${floor}&t=${type}`,
      cb_ok,
      cb_err,
    );
  }

  replyFeedback(cid, fid, desc, files, images, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Desc", desc);
    if (files) {
      files.forEach((f) => {
        formData.append("File", f);
      });
    }
    if (images) {
      images.forEach((i) => {
        formData.append("Image", i);
      });
    }

    this.commonPost(`/community/${cid}/feedback/${fid}`, formData, cb_ok, cb_err);
  }

  editFeedback(cid, fid, desc, files, images, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Desc", desc);
    if (files) {
      files.forEach((f) => {
        formData.append("File", f);
      });
    }
    if (images) {
      images.forEach((i) => {
        formData.append("Image", i);
      });
    }

    this.commonPut(`/community/${cid}/feedback/${fid}`, formData, cb_ok, cb_err);
  }

  // Feedback Type
  getFeedbackTypeItemList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/feedback_type_list`, cb_ok, cb_err);
  }

  addFeedbackTypeItem(cid, itemName, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/feedback_type?t=${itemName}`, null, cb_ok, cb_err);
  }

  updateFeedbackTypeItem(cid, items, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/feedback_type`, items, cb_ok, cb_err);
  }

  deleteFeedbackTypeItem(cid, itemName, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/feedback_type?t=${itemName}`, cb_ok, cb_err);
  }

  // Rules
  addRules(cid, rulesData, files, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(rulesData));
    if (files) {
      files.forEach((f) => {
        formData.append("File", f);
      });
    }
    this.commonPost(`/community/${cid}/rules`, formData, cb_ok, cb_err);
  }

  updateRules(cid, rulesData, files, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(rulesData));
    if (files) {
      files.forEach((f) => {
        formData.append("File", f);
      });
    }
    this.commonPut(`/community/${cid}/rules`, formData, cb_ok, cb_err);
  }

  getRulesList(cid, { sortBy, sortDesc, page, itemsPerPage, startDate, endDate, type, search }, cb_ok, cb_err) {
    if (!startDate) startDate = "";
    if (!endDate) endDate = "";
    if (!type) type = "";
    if (!search) search = "";

    this.commonGet(
      `/community/${cid}/rules_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&st=${startDate}&et=${endDate}&t=${type}&ss=${search}`,
      cb_ok,
      cb_err,
    );
  }

  deleteRules(cid, nID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/rules/${nID}`, cb_ok, cb_err);
  }

  // Rules Type
  getRulesTypeItemList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/rules_type_list`, cb_ok, cb_err);
  }

  addRulesTypeItem(cid, itemName, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/rules_type?t=${itemName}`, null, cb_ok, cb_err);
  }

  updateRulesTypeItem(cid, items, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/rules_type`, items, cb_ok, cb_err);
  }

  deleteRulesTypeItem(cid, itemName, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/rules_type?t=${itemName}`, cb_ok, cb_err);
  }

  // Vote
  getVoteList(cid, { sortBy, sortDesc, page, itemsPerPage, startDate, endDate, voteByHouseHold, important, canMessage, search }, cb_ok, cb_err) {
    if (!startDate) startDate = "";
    if (!endDate) endDate = "";
    if (!voteByHouseHold) voteByHouseHold = "";
    if (!important) important = "";
    if (!canMessage) canMessage = "";
    if (!search) search = "";

    this.commonGet(
      `/community/${cid}/vote_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&st=${startDate}&et=${endDate}&vh=${voteByHouseHold}&imp=${important}&cm=${canMessage}&ss=${search}`,
      cb_ok,
      cb_err,
    );
  }

  getOptionVoteList(cid, vid, qid, oid, { sortBy, sortDesc, page, itemsPerPage }, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/vote/${vid}/${qid}/${oid}/vote_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}`, cb_ok, cb_err);
  }

  addVote(cid, voteData, files, images, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(voteData));
    if (files) {
      files.forEach((f) => {
        formData.append("File", f);
      });
    }
    if (images) {
      images.forEach((i) => {
        formData.append("Image", i);
      });
    }

    this.commonPost(`/community/${cid}/vote`, formData, cb_ok, cb_err);
  }

  updateVote(cid, voteData, files, images, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(voteData));
    if (files) {
      files.forEach((f) => {
        formData.append("File", f);
      });
    }
    if (images) {
      images.forEach((i) => {
        formData.append("Image", i);
      });
    }

    this.commonPut(`/community/${cid}/vote`, formData, cb_ok, cb_err);
  }

  notifyVoteAgain(cid, voteID, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/vote/${voteID}/notify_again`, null, cb_ok, cb_err);
  }

  publishVote(cid, voteID, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/vote/${voteID}/publish`, null, cb_ok, cb_err);
  }

  getVoteMessageList(cid, voteID, { sortBy, sortDesc, page, itemsPerPage, startDate, endDate, search, building, doorPlate, floor }, cb_ok, cb_err) {
    if (!startDate) startDate = "";
    if (!endDate) endDate = "";
    if (!search) search = "";
    if (!building) building = "";
    if (!doorPlate) doorPlate = "";
    if (!floor) floor = "";

    this.commonGet(
      `/community/${cid}/vote/${voteID}/message_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&st=${startDate}&et=${endDate}&ss=${search}&b=${building}&d=${doorPlate}&f=${floor}`,
      cb_ok,
      cb_err,
    );
  }

  setVoteMessageHide(cid, voteID, messageID, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/vote/${voteID}/message/${messageID}/hide`, null, cb_ok, cb_err);
  }

  unsetVoteMessageHide(cid, voteID, messageID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/vote/${voteID}/message/${messageID}/hide`, null, cb_ok, cb_err);
  }

  deleteVote(cid, voteID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/vote/${voteID}`, cb_ok, cb_err);
  }

  /** [社區投票] 獲取投票類型列表 */
  getVoteTypeItemList() {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/vote_type_list`);
  }

  /** [社區投票] 新增投票類型
   * @param {string} itemName 類型名稱
   */
  addVoteTypeItem(itemName) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/vote_type?t=${itemName}`);
  }

  /** [社區投票] 更新投票類型排序
   * @param {*} items 項目列表
   */
  updateVoteTypeItem(items) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPut2(`/community/${cid}/vote_type`, items);
  }

  /** [社區投票] 獲取投票類型列表
   * @param {string} itemName 類型名稱
   */
  deleteVoteTypeItem(itemName) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonDelete2(`/community/${cid}/vote_type?t=${itemName}`);
  }

  // Gas
  addGasForm(cid, month, endTime, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/gas?m=${month}&et=${endTime}`, null, cb_ok, cb_err);
  }

  updateGasForm(cid, fid, month, endTime, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/gas/${fid}?m=${month}&et=${endTime}`, null, cb_ok, cb_err);
  }

  deleteGasForm(cid, fid, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/gas/${fid}`, cb_ok, cb_err);
  }

  getGasFormList(cid, { month, sortBy, sortDesc, page, itemsPerPage }, cb_ok, cb_err) {
    if (!month) month = "";

    this.commonGet(`/community/${cid}/gas_list?m=${month}&p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}`, cb_ok, cb_err);
  }

  /** [瓦斯抄表] 發送登記通知
   * @param {string} fid 抄表項目ID
   */
  notifyGasForm(fid) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/gas/${fid}/notify`);
  }

  getGasDataList(cid, fid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/gas/${fid}/list`, cb_ok, cb_err);
  }

  setGasDataUseValue(cid, fid, { useValue, building, doorPlate, floor }, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/gas/${fid}/value?v=${useValue}&b=${building}&d=${doorPlate}&f=${floor}`, null, cb_ok, cb_err);
  }

  getGasDataExportXlsFileUrl(cid, fid) {
    return `${this.url}/community/${cid}/gas/${fid}/export`;
  }

  // MgmtFeePeriod
  addMgmtFeePeriod(cid, periodData, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/mgmtfee/period`, periodData, cb_ok, cb_err);
  }

  updateMgmtFeePeriod(cid, periodData, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/mgmtfee/period`, periodData, cb_ok, cb_err);
  }

  getMgmtFeePeriodList(cid, { search, sortBy, sortDesc, page, itemsPerPage }, cb_ok, cb_err) {
    if (!search) search = "";

    this.commonGet(`/community/${cid}/mgmtfee/period_list?s=${search}&p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}`, cb_ok, cb_err);
  }

  deleteMgmtFeePeriod(cid, pid, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/mgmtfee/period/${pid}`, cb_ok, cb_err);
  }

  openMgmtFeePeriod(cid, pid, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/mgmtfee/period/${pid}/open`, null, cb_ok, cb_err);
  }

  closeMgmtFeePeriod(cid, pid, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/mgmtfee/period/${pid}/close`, null, cb_ok, cb_err);
  }

  // MgmtFee Item
  getMgmtFeeItemList(cid, pid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/mgmtfee/period/${pid}/item_list`, cb_ok, cb_err);
  }

  addMgmtFeeItem(cid, pid, itemName, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/mgmtfee/period/${pid}/item?t=${itemName}`, null, cb_ok, cb_err);
  }

  updateMgmtFeeItem(cid, pid, items, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/mgmtfee/period/${pid}/item`, items, cb_ok, cb_err);
  }

  deleteMgmtFeeItem(cid, pid, itemName, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/mgmtfee/period/${pid}/item?t=${itemName}`, cb_ok, cb_err);
  }

  // MgmtFeePeriod Bill
  getMgmtFeePeriodBillList(cid, pid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/mgmtfee/period/${pid}/bill_list`, cb_ok, cb_err);
  }

  getMgmtFeePeriodBillPayList(cid, pid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/mgmtfee/period/${pid}/bill_pay_list`, cb_ok, cb_err);
  }

  updateMgmtFeePeriodBill(cid, pid, bill, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/mgmtfee/period/${pid}/bill`, bill, cb_ok, cb_err);
  }

  getMgmtFeeBillExportXlsFileUrl(cid, pid) {
    return `${this.url}/community/${cid}/mgmtfee/period/${pid}/export`;
  }

  importMgmtFeeBillList(cid, pid, xlsxFile, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Xlsx", xlsxFile);
    this.commonPost(`/community/${cid}/mgmtfee/period/${pid}/import`, formData, cb_ok, cb_err);
  }

  payMgmtFeeBillToStaff(cid, pid, bid, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/mgmtfee/period/${pid}/${bid}/pay_to_staff`, null, cb_ok, cb_err);
  }

  cancelPayMgmtFeeBillToStaff(cid, pid, bid, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/mgmtfee/period/${pid}/${bid}/pay_to_staff`, cb_ok, cb_err);
  }

  notifyPayMgmtFeeBillUnpaid(cid, pid, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/mgmtfee/period/${pid}/notify_unpaid`, null, cb_ok, cb_err);
  }

  getMgmtFeeBillPayInfoExportXlsFileUrl(cid, pid) {
    return `${this.url}/community/${cid}/mgmtfee/period/${pid}/export_payinfo`;
  }

  // MgmtFee Sinopac Pay
  updateMgmtFeePayData(cid, oid, data, cb_ok, cb_err) {
    this.commonPut(`/pay/sinopac/mgmt_fee/${cid}/${oid}`, data, cb_ok, cb_err);
  }

  // Account

  /** [社區帳號] 獲取管理者列表 */
  getAccountList() {
    const cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/account_list`);
  }

  removeCommunityAdmin(cid, uid, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/account/${uid}`, cb_ok, cb_err);
  }

  updateCommunityAdminRole(cid, uid, rid, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/account/${uid}/role/${rid}`, null, cb_ok, cb_err);
  }

  getCommunityAdminActiveCode(cid, rid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/account/role/${rid}/activecode`, cb_ok, cb_err);
  }

  getCommunityAdminActiveCodeList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/account/activecode_list`, cb_ok, cb_err);
  }

  deleteCommunityAdminActiveCode(cid, code, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/account/activecode?c=${code}`, cb_ok, cb_err);
  }

  // Account Role

  /** [社區帳號] 獲取管理身分列表 */
  getAccountRoleList() {
    const cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/account_role_list`);
  }

  addAccountRole(cid, role, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/account_role`, role, cb_ok, cb_err);
  }

  updateAccountRole(cid, role, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/account_role`, role, cb_ok, cb_err);
  }

  deleteAccountRole(cid, rid, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/account_role/${rid}`, cb_ok, cb_err);
  }

  // Calendar
  getCalendarEventList(cid, startTime, endTime, cb_ok, cb_err) {
    console.log(startTime, endTime);
    this.commonGet(`/community/${cid}/calendar/event_list?st=${startTime}&et=${endTime}`, cb_ok, cb_err);
  }

  addCalendarEvent(cid, event, files, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(event));

    if (files) {
      files.forEach((file) => {
        formData.append("File", file);
      });
    }

    this.commonPost(`/community/${cid}/calendar/event`, formData, cb_ok, cb_err);
  }

  updateCalendarEvent(cid, event, files, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(event));

    if (files) {
      files.forEach((file) => {
        formData.append("File", file);
      });
    }

    this.commonPut(`/community/${cid}/calendar/event`, formData, cb_ok, cb_err);
  }

  deleteCalendarEvent(cid, eventid, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/calendar/event/${eventid}`, cb_ok, cb_err);
  }

  // cctv
  getMediaSourceList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/cctv/media_source_list`, cb_ok, cb_err);
  }

  addMediaSource(cid, ms, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/cctv/media_source`, ms, cb_ok, cb_err);
  }

  updateMediaSource(cid, ms, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/cctv/media_source`, ms, cb_ok, cb_err);
  }

  requestVideo(cid, msid, uuid, sdp, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("uuid", uuid);
    formData.set("sdp", sdp);

    this.commonPost(`/community/${cid}/cctv/${msid}/request`, formData, cb_ok, cb_err);
  }

  // intercom

  /** [雲對講] 獲取站點列表 */
  getIntercomStationList() {
    const cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/intercom/list/station`);
  }

  /** [雲對講] 獲取用戶列表 */
  getIntercomHouseHoldList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/intercom/list/household`, cb_ok, cb_err);
  }

  /** [雲對講] 新增站點
   * @param {*} is 站點資料
   */
  addIntercomStation(is) {
    const cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/intercom/station`, is);
  }

  /** [雲對講] 更新站點
   * @param {*} 站點資料
   */
  updateIntercomStation(is) {
    const cid = store.getters.getSelectCommunity.id;
    return this.commonPut2(`/community/${cid}/intercom/station`, is);
  }

  /** [雲對講] 刪除站點 */
  deleteIntercomStation(cid, isID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/intercom/station/${isID}`, cb_ok, cb_err);
  }

  // asset type
  getAssetTypeList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/asset_type_list`, cb_ok, cb_err);
  }

  addAssetType(cid, newItem, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/asset_type`, newItem, cb_ok, cb_err);
  }

  updateAssetType(cid, itemList, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/asset_type`, itemList, cb_ok, cb_err);
  }

  deleteAssetType(cid, atID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/asset_type/${atID}`, cb_ok, cb_err);
  }

  // asset system
  getAssetSystemList(cid, atID, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/asset_type/${atID}/system_list`, cb_ok, cb_err);
  }

  addAssetSystem(cid, atID, newItem, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/asset_type/${atID}/system`, newItem, cb_ok, cb_err);
  }

  updateAssetSystem(cid, atID, itemList, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/asset_type/${atID}/system`, itemList, cb_ok, cb_err);
  }

  deleteAssetSystem(cid, atID, stID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/asset_type/${atID}/system/${stID}`, cb_ok, cb_err);
  }

  // asset place
  getAssetPlaceList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/asset_place_list`, cb_ok, cb_err);
  }

  addAssetPlace(cid, placeName, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/asset_place?p=${placeName}`, null, cb_ok, cb_err);
  }

  updateAssetPlace(cid, places, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/asset_place`, places, cb_ok, cb_err);
  }

  deleteAssetPlace(cid, placeName, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/asset_place?p=${placeName}`, cb_ok, cb_err);
  }

  // asset
  getAssetOverview(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/asset_overview`, cb_ok, cb_err);
  }

  getAssetList(cid, assetSystem, assetName, assetPlace, assetStatus, assetProcess, { sortBy, sortDesc, page, itemsPerPage }, cb_ok, cb_err) {
    if (!assetName) assetName = "";
    if (!assetPlace) assetPlace = "";
    if (!assetStatus) assetStatus = "";
    if (!assetProcess) assetProcess = "";
    this.commonGet(
      `/community/${cid}/asset_list?at=${assetSystem.Name}&as=${assetSystem.System.Name}&an=${assetName}&aa=${assetPlace}&ass=${assetStatus}&ap=${assetProcess}&p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}`,
      cb_ok,
      cb_err,
    );
  }

  addAsset(cid, assetData, imageFiles, files, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(assetData));
    if (imageFiles) {
      imageFiles.forEach((f) => {
        formData.append("Image", f);
      });
    }
    if (files) {
      files.forEach((f) => {
        formData.append("File", f);
      });
    }
    this.commonPost(`/community/${cid}/asset`, formData, cb_ok, cb_err);
  }

  updateAsset(cid, assetData, imageFiles, files, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(assetData));
    if (imageFiles) {
      imageFiles.forEach((f) => {
        formData.append("Image", f);
      });
    }
    if (files) {
      files.forEach((f) => {
        formData.append("File", f);
      });
    }
    this.commonPut(`/community/${cid}/asset`, formData, cb_ok, cb_err);
  }

  deleteAsset(cid, assetID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/asset/${assetID}`, cb_ok, cb_err);
  }

  // vendor
  addVendor(cid, vendorData, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/vendor`, vendorData, cb_ok, cb_err);
  }

  updateVendor(cid, vendorData, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/vendor`, vendorData, cb_ok, cb_err);
  }

  getVendorList(cid, { sortBy, sortDesc, page, itemsPerPage, search }, cb_ok, cb_err) {
    if (!search) search = "";
    this.commonGet(`/community/${cid}/vendor_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&ss=${search}`, cb_ok, cb_err);
  }

  deleteVendor(cid, vID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/vendor/${vID}`, cb_ok, cb_err);
  }

  getVendorXlsFileUrl(cid) {
    return `${this.url}/community/${cid}/vendor/export`;
  }

  // storage place
  getStoragePlaceList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/storage_place_list`, cb_ok, cb_err);
  }

  addStoragePlace(cid, placeName, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/storage_place?p=${placeName}`, null, cb_ok, cb_err);
  }

  updateStoragePlace(cid, places, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/storage_place`, places, cb_ok, cb_err);
  }

  deleteStoragePlace(cid, placeName, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/storage_place?p=${placeName}`, cb_ok, cb_err);
  }

  // storage type
  getStorageTypeList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/storage_type_list`, cb_ok, cb_err);
  }

  addStorageType(cid, typeName, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/storage_type?p=${typeName}`, null, cb_ok, cb_err);
  }

  updateStorageType(cid, types, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/storage_type`, types, cb_ok, cb_err);
  }

  deleteStorageType(cid, typeName, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/storage_type?p=${typeName}`, cb_ok, cb_err);
  }

  // storage unit
  getStorageUnitList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/storage_unit_list`, cb_ok, cb_err);
  }

  addStorageUnit(cid, unitName, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/storage_unit?p=${unitName}`, null, cb_ok, cb_err);
  }

  updateStorageUnit(cid, units, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/storage_unit`, units, cb_ok, cb_err);
  }

  deleteStorageUnit(cid, unitName, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/storage_unit?p=${unitName}`, cb_ok, cb_err);
  }

  // storage
  getStorageList(cid, storageType, storageName, storagePlace, storageStatus, { sortBy, sortDesc, page, itemsPerPage }, cb_ok, cb_err) {
    if (!storageType) storageType = "";
    if (!storageName) storageName = "";
    if (!storagePlace) storagePlace = "";
    if (!storageStatus) storageStatus = "";
    this.commonGet(
      `/community/${cid}/storage_list?st=${storageType}&sn=${storageName}&sp=${storagePlace}&ss=${storageStatus}&p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}`,
      cb_ok,
      cb_err,
    );
  }

  addStorage(cid, storageData, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/storage`, storageData, cb_ok, cb_err);
  }

  updateStorage(cid, storageData, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/storage`, storageData, cb_ok, cb_err);
  }

  deleteStorage(cid, storageID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/storage/${storageID}`, cb_ok, cb_err);
  }

  updateStorageStock(cid, schData, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/storage_stock`, schData, cb_ok, cb_err);
  }

  getStorageChangeHistory(cid, sid, startTime, endTime, changeType, { sortBy, sortDesc, page, itemsPerPage }, cb_ok, cb_err) {
    if (!startTime) startTime = "";
    if (!endTime) endTime = "";
    if (!changeType) changeType = "";
    this.commonGet(
      `/community/${cid}/storage_change_history?sid=${sid}&st=${startTime}&et=${endTime}&ct=${changeType}&p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}`,
      cb_ok,
      cb_err,
    );
  }

  // building data
  getBuildingData(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/building_data`, cb_ok, cb_err);
  }

  updateBuildingData(cid, buildingData, images, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(buildingData));
    if (images) {
      images.forEach((img) => {
        formData.append("Image", img);
      });
    }
    this.commonPut(`/community/${cid}/building_data`, formData, cb_ok, cb_err);
  }

  // household certificate
  getHouseholdCertificateList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/household_certificate_list`, cb_ok, cb_err);
  }

  updateHouseholdCertificate(cid, householdCertificateData, images, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(householdCertificateData));
    if (images) {
      images.forEach((img) => {
        formData.append("Image", img);
      });
    }
    this.commonPut(`/community/${cid}/household_certificate`, formData, cb_ok, cb_err);
  }

  getHouseholdCertificateXlsFileUrl(cid) {
    return `${this.url}/community/${cid}/household_certificate/export`;
  }

  importHouseholdCertificateList(cid, xlsxFile, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Xlsx", xlsxFile);
    this.commonPost(`/community/${cid}/household_certificate/import`, formData, cb_ok, cb_err);
  }

  // household rent data
  getHouseholdRentDataList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/household_rent_data_list`, cb_ok, cb_err);
  }

  houseHoldRentMoveIn(cid, rentData, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/household_rent_move_in`, rentData, cb_ok, cb_err);
  }

  updateHouseHoldRentData(cid, rentData, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/household_rent_data`, rentData, cb_ok, cb_err);
  }

  houseHoldRentMoveOut(cid, { building, doorPlate, floor }, date, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/household_rent_move_out?b=${building}&d=${doorPlate}&f=${floor}&od=${date}`, null, cb_ok, cb_err);
  }

  getHouseholdRentHistoryList(cid, { building, doorPlate, floor }, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/household_rent_history_list?b=${building}&d=${doorPlate}&f=${floor}`, cb_ok, cb_err);
  }

  // ======================= 車位管理相關 =======================

  /** [車位管理] 獲取列表 */
  getParkingList() {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/parking_list`);
  }

  /** [車位管理] 新增車位
   * @param {*} parkingData 車位資訊
   */
  addParking(parkingData) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/parking`, parkingData);
  }

  /** [車位管理]  更新車位資訊
   * @param {*} parkingData 車位資訊
   */
  updateParking(parkingData) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPut2(`/community/${cid}/parking`, parkingData);
  }

  /** [車位管理] 刪除車位資訊
   * @param {string} vID 車位項目ID
   */
  deleteParking(vID) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonDelete2(`/community/${cid}/parking/${vID}`);
  }

  /** [車位管理] 獲取資料表匯出路徑 */
  getParkingXlsFileUrl(cid) {
    return `${this.url}/community/${cid}/parking/export`;
  }

  /** [車位管理] 匯入檔案 */
  importParkingList(xlsxFile) {
    var formData = new FormData();
    formData.set("Xlsx", xlsxFile);
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/parking/import`, formData);
  }

  // internal people
  addInternalPeople(cid, internalPeopleData, imageFiles, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(internalPeopleData));
    if (imageFiles) {
      imageFiles.forEach((f) => {
        formData.append("Image", f);
      });
    }
    this.commonPost(`/community/${cid}/internal_people`, formData, cb_ok, cb_err);
  }

  updateInternalPeople(cid, internalPeopleData, imageFiles, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Data", JSON.stringify(internalPeopleData));
    if (imageFiles) {
      imageFiles.forEach((f) => {
        formData.append("Image", f);
      });
    }
    this.commonPut(`/community/${cid}/internal_people`, formData, cb_ok, cb_err);
  }

  getInternalPeopleList(cid, { sortBy, sortDesc, page, itemsPerPage, search }, cb_ok, cb_err) {
    if (!search) search = "";
    this.commonGet(`/community/${cid}/internal_people_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&ss=${search}`, cb_ok, cb_err);
  }

  getInternalPeopleWithDepartmentList(cid, department, cb_ok, cb_err) {
    if (!department) department = "";
    this.commonGet(`/community/${cid}/internal_people_list_department?d=${department}`, cb_ok, cb_err);
  }

  deleteInternalPeople(cid, vID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/internal_people/${vID}`, cb_ok, cb_err);
  }

  getInternalPeopleXlsFileUrl(cid) {
    return `${this.url}/community/${cid}/internal_people/export`;
  }

  importInternalPeopleList(cid, xlsxFile, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("Xlsx", xlsxFile);
    this.commonPost(`/community/${cid}/internal_people/import`, formData, cb_ok, cb_err);
  }

  getCommunityAdminCanBindInternalPeopleUserList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/internal_people/can_bind_user_list`, cb_ok, cb_err);
  }

  bindInternalPeopleUser(cid, internalPeopleID, userID, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/internal_people/${internalPeopleID}/bind/${userID}`, null, cb_ok, cb_err);
  }

  unbindInternalPeopleUser(cid, internalPeopleID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/internal_people/${internalPeopleID}/bind`, cb_ok, cb_err);
  }

  // commissioner
  addCommissioner(cid, commissionerData, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/commissioner`, commissionerData, cb_ok, cb_err);
  }

  updateCommissioner(cid, commissionerData, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/commissioner`, commissionerData, cb_ok, cb_err);
  }

  getCommissionerList(cid, { sortBy, sortDesc, page, itemsPerPage, search }, cb_ok, cb_err) {
    if (!search) search = "";
    this.commonGet(`/community/${cid}/commissioner_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&ss=${search}`, cb_ok, cb_err);
  }

  deleteCommissioner(cid, vID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/commissioner/${vID}`, cb_ok, cb_err);
  }

  getCommissionerXlsFileUrl(cid) {
    return `${this.url}/community/${cid}/commissioner/export`;
  }

  getCommunityAdminCanBindCommissionerUserList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/commissioner/can_bind_user_list`, cb_ok, cb_err);
  }

  bindCommissionerUser(cid, commissionerID, userID, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/commissioner/${commissionerID}/bind/${userID}`, null, cb_ok, cb_err);
  }

  unbindCommissionerUser(cid, commissionerID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/commissioner/${commissionerID}/bind`, cb_ok, cb_err);
  }

  // supervision record
  addSupervisionRecord(cid, supervisionRecordData, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/supervision_record`, supervisionRecordData, cb_ok, cb_err);
  }

  updateSupervisionRecord(cid, supervisionRecordData, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/supervision_record`, supervisionRecordData, cb_ok, cb_err);
  }

  getSupervisionRecordList(cid, { sortBy, sortDesc, page, itemsPerPage, search }, cb_ok, cb_err) {
    if (!search) search = "";
    this.commonGet(`/community/${cid}/supervision_record_list?p=${page}&sb=${sortBy}&sd=${sortDesc}&pp=${itemsPerPage}&ss=${search}`, cb_ok, cb_err);
  }

  deleteSupervisionRecord(cid, vID, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/supervision_record/${vID}`, cb_ok, cb_err);
  }

  getSupervisionRecordXlsFileUrl(cid) {
    return `${this.url}/community/${cid}/supervision_record/export`;
  }

  // department
  getDepartmentList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/department_list`, cb_ok, cb_err);
  }

  addDepartment(cid, typeName, cb_ok, cb_err) {
    this.commonPost(`/community/${cid}/department?p=${typeName}`, null, cb_ok, cb_err);
  }

  updateDepartment(cid, types, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/department`, types, cb_ok, cb_err);
  }

  deleteDepartment(cid, typeName, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/department?p=${typeName}`, cb_ok, cb_err);
  }

  // shift
  getShiftList(cid, month, name, department, cb_ok, cb_err) {
    if (!name) name = "";
    if (!department) department = "";
    this.commonGet(`/community/${cid}/shift_list?m=${month}&n=${name}&d=${department}`, cb_ok, cb_err);
  }

  updateShift(cid, shift, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/shift`, shift, cb_ok, cb_err);
  }

  updateShiftBatch(cid, month, users, weeks, shift, cb_ok, cb_err) {
    var formData = new FormData();
    formData.set("s", JSON.stringify(shift));

    this.commonPut(`/community/${cid}/shift_batch?m=${month}&u=${users}&w=${weeks}`, formData, cb_ok, cb_err);
  }

  deleteShift(cid, uid, year, month, day, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/shift?u=${uid}&d=${year}-${month}-${day}`, cb_ok, cb_err);
  }

  deleteShiftBatch(cid, month, users, cb_ok, cb_err) {
    this.commonDelete(`/community/${cid}/shift_batch?m=${month}&u=${users}`, cb_ok, cb_err);
  }

  getShiftXlsFileUrl(cid, month) {
    return `${this.url}/community/${cid}/shift/export?m=${month}`;
  }

  // shift type
  getShiftTypeList(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/shift_type_list`, cb_ok, cb_err);
  }

  updateShiftType(cid, types, cb_ok, cb_err) {
    this.commonPut(`/community/${cid}/shift_type`, types, cb_ok, cb_err);
  }

  // shift code
  getShiftCheckCode(cid, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/shift_check_code`, cb_ok, cb_err);
  }

  // attendance info
  getAttendanceInfo(cid, date, cb_ok, cb_err) {
    this.commonGet(`/community/${cid}/attendance_info?d=${date}`, cb_ok, cb_err);
  }

  getAttendanceRecords(cid, startDate, endDate, department, internalPeopleID, cb_ok, cb_err) {
    if (!department) department = "";
    if (!internalPeopleID) internalPeopleID = "";

    this.commonGet(`/community/${cid}/attendance_records?s=${startDate}&e=${endDate}&d=${department}&i=${internalPeopleID}`, cb_ok, cb_err);
  }

  // ======================= 住戶報修相關 =======================

  /** [住戶報修] 獲取報修列表
   * @param search 查詢條件
   */
  getRepairList(search) {
    let query = `p=${search.page}&sb=${search.sortBy}&sd=${search.sortDesc}&pp=${search.itemsPerPage}`;
    if (search.startDate) query += `&st=${search.startDate}`;
    if (search.endDate) query += `&et=${search.endDate}`;
    if (search.hasRead != null) query += `&hr=${search.hasRead}`;
    if (search.state != null) query += `&s=${search.state}`;
    if (search.building) query += `&b=${search.building}`;
    if (search.doorPlate) query += `&d=${search.doorPlate}`;
    if (search.floor) query += `&f=${search.floor}`;

    let cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/report_for_repair_list?${query}`);
  }

  /** [住戶報修] 回覆消息
   * @param {string} id 項目ID
   * @param {string} desc 回覆內容
   * @param {string|number} state 處理狀態(0:待處理、1:處理中、2:已完成)
   * @param {*} files 檔案列表
   * @param {*} images 圖片列表
   */
  replyRepair(id, desc, state, files, images) {
    var formData = new FormData();
    formData.set("Desc", desc);
    formData.set("State", state.toString());
    files?.forEach((f) => formData.append("File", f));
    images?.forEach((i) => formData.append("Image", i));

    let cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/report_for_repair/${id}`, formData);
  }

  /** [住戶報修] 編輯回覆消息
   * @param {string} id 項目ID
   * @param {string} desc 回覆內容
   * @param {string|number} state 處理狀態(0:待處理、1:處理中、2:已完成)
   * @param {*} files 檔案列表
   * @param {*} images 圖片列表
   */
  editRepair(id, desc, state, files, images) {
    var formData = new FormData();
    formData.set("Desc", desc);
    formData.set("State", state.toString());
    files?.forEach((f) => formData.append("File", f));
    images?.forEach((i) => formData.append("Image", i));

    let cid = store.getters.getSelectCommunity.id;
    return this.commonPut2(`/community/${cid}/report_for_repair/${id}`, formData);
  }

  // ======================= 文檔管理相關 =======================

  /** [文檔管理] 獲取檔案列表 */
  getDocuments() {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/documents`);
  }

  /** [文檔管理] 上傳檔案
   * @param {object | object[]} files 檔案列表(單個或陣列)
   * @param {string} prefix 指定路徑
   */
  uploadDocument(files, prefix) {
    var formData = new FormData();

    if (prefix) formData.append("prefix", prefix);

    if (files) {
      if (Array.isArray(files)) {
        files.forEach((file) => formData.append("file", file));
      } else {
        formData.append("file", files);
      }
    }
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/document`, formData);
  }

  /** [文檔管理] 刪除檔案
   * @param {string} filePath 檔案路徑
   */
  deleteDocument(filePath) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonDelete2(`/community/${cid}/document?path=${filePath}`);
  }

  /** [文檔管理] 重新命名
   * @param {string} filePath 檔案路徑
   * @param {string} newName 新的檔案名稱(不含附檔名)
   */
  renameDocument(filePath, newName) {
    var formData = new FormData();
    formData.append("path", filePath);
    formData.append("name", newName);
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPut2(`/community/${cid}/document/name`, formData);
  }

  /** [文檔管理-建商交接] 獲取檔案列表 */
  getDocuments_Handover() {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/documents/handover`);
  }

  /** [文檔管理-建商交接] 上傳檔案
   * @param {Map<string,File>} dataMap 檔案集合
   */
  uploadDocument_Handover(dataMap) {
    var formData = new FormData();
    for (let [key, file] of dataMap) {
      formData.append(key, file);
    }
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/document/handover`, formData);
  }

  /** [文檔管理-建商交接] 獲取版本紀錄
   * @param {string} path 檔案路徑
   */
  getDocumentVersions_Handover(path) {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/document/handover/versions?path=${path}`);
  }

  /** [文檔管理-建商交接] 獲取檔案 URL
   * @param {string} path 檔案路徑
   * @param {string} vid 版本ID
   */
  getDocumentFileUrl_Handover(path, vid = null) {
    let cid = store.getters.getSelectCommunity.id;
    let vidStr = vid ? `&vid=${vid}` : "";
    let token = store.getters.getToken;
    return `${this.url}/community/${cid}/document/handover/file?path=${path}${vidStr}&token=${token}`;
  }

  /** [文檔管理-建商交接] 獲取檔案 Blob
   * @param {string} path 檔案路徑
   * @param {string} vid 版本ID
   */
  getDocumentFileBlob_Handover(path, vid = null) {
    let cid = store.getters.getSelectCommunity.id;
    let vidStr = vid ? `&vid=${vid}` : "";
    let token = store.getters.getToken;
    return this.commonGet2(`/community/${cid}/document/handover/file?path=${path}${vidStr}&token=${token}`, {
      responseType: "blob",
    });
  }

  // ======================= 雲對講相關 =======================

  /** [雲對講] 發送通話請求
   * @param {string} hid 戶別 ID
   * @param {string} uuid 自身產生的 UUID (須與連接 WebSocket 的一致)
   * @param {string} sdp RTCPreeConnection 產生的本地描述
   **/
  sendOffer(hid, uuid, sdp) {
    let cid = store.getters.getSelectCommunity.id;
    var formData = new FormData();
    formData.set("hid", hid);
    formData.set("uuid", uuid);
    formData.set("sdp", sdp);
    return this.commonPost2(`/community/${cid}/intercom/call/household`, formData);
  }

  /** [雲對講] 接受通話請求
   * @param {string} callerUID 發送端 UID
   * @param {string} receiverID 自身 UID
   * @param {string} sdp 自身本地訊息
   **/
  sendAnswer(callerUID, receiverID, sdp) {
    let fcmToken = "web_not_token"; // 自身 FCM Token (Web 現在沒這東西...)

    var formData = new FormData();
    formData.set("sdp", sdp);

    return this.commonPost2(`/intercom/answer/${callerUID}?rid=${receiverID}&fcm=${fcmToken}`, formData);
  }

  /** [雲對講] 結束通話
   * @param {string} uuid 當前產生的 UUID
   */
  intercomHangup(uuid) {
    return this.commonDelete2(`/intercom/hangup/${uuid}`);
  }

  /** [雲對講] 獲取 SDP */
  getIntercomCallInfoSDP(uuid) {
    return this.commonGet2(`/intercom/caller/${uuid}/sdp`);
  }

  /** [語音留言] 發送語音留言
   * @param {string} hid 戶別 ID
   * @param {Blob} blob 音訊數據
   */
  sendVoiceMessage(hid, blob) {
    let file = new File([blob], "msg.wav", {
      type: blob.type,
      lastModified: Date.now(),
    });
    var formData = new FormData();
    formData.append("hid", hid);
    formData.append("file", file);
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/intercom/list/voice_message`, formData);
  }

  /** [語音留言] 獲取列表 */
  getVoiceMessages() {
    let cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/intercom/list/voice_message`);
  }

  /** [語音留言] 設置已讀
   * @param {string} id 項目ID
   */
  readVoiceMessages(id) {
    var formData = new FormData();
    formData.set("id", id);
    let cid = store.getters.getSelectCommunity.id;
    return this.commonPut2(`/community/${cid}/intercom/list/voice_message/read`, formData);
  }

  /** [未接紀錄] 獲取列表 */
  getMissedCall() {
    const cid = store.getters.getSelectCommunity.id;
    return this.commonGet2(`/community/${cid}/intercom/list/missed`);
  }

  /** [未接紀錄] 添加紀錄
   * @param {string} hid 目標戶別ID
   */
  addMissedCall(hid) {
    var formData = new FormData();
    formData.set("hid", hid);
    const cid = store.getters.getSelectCommunity.id;
    return this.commonPost2(`/community/${cid}/intercom/list/missed`, formData);
  }

  /** [未接紀錄] 設置已讀
   * @param {string} id 項目ID
   */
  readMissedCall(id) {
    var formData = new FormData();
    formData.set("id", id);
    const cid = store.getters.getSelectCommunity.id;
    return this.commonPut2(`/community/${cid}/intercom/list/missed/read`, formData);
  }

  /** Demo - [雲對講] 監聽通話請求 ( 待音箱 FCM 機制完整才能刪 )
   * @param {Function<bool>} 回調請求狀態
   */
  setOnOfferStateListener(onOfferState) {
    // 請求間隔(s)
    const times = 0.5;
    // 限定時間範圍(m)
    const limit = 5;
    // 設置每秒呼叫一次的定時器
    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        let user = store.getters.getUser;
        // 確保為登入時，才監聽來電狀態
        if (user) {
          this.commonGet2(`/demo/web/call_info?uid=${user.id}`)
            .then((data) => {
              if (data) {
                // ======== Chime 流程 ========
                if (data.meetingId) {
                  console.log("data.meetingId : ", data.meetingId);
                  console.log("this.checkOfferData?.meetingId : ", this.checkOfferData?.meetingId);

                  if (this.checkOfferData?.meetingId != data.meetingId) {
                    this.checkOfferData = data;
                    onOfferState(this.checkOfferData);
                  }
                  return;
                }

                // ======== WebRTC 流程 ========
                console.log("=== callerUID : ", data.callerUID);

                // 檢查時間差是否範圍內
                let difference = ~~(Date.now() / 1000) - data.time;
                if (difference < limit * 60 && this.checkOfferData?.callerUID != data.callerUID) {
                  this.checkOfferData = data;
                  onOfferState(this.checkOfferData);
                }
              } else if (this.checkOfferData) {
                this.checkOfferData = data;
                onOfferState(this.checkOfferData);
              }
            })
            .catch((e) => console.log(e));
        }
      }, times * 1000);
    }
  }

  /** Demo - [雲對講] 清除通話請求狀態 ( 待音箱 FCM 機制完整才能刪 )
   * @param {string} callerUID 撥打端 UUID (WebRTC)
   * @param {string} meetingId 撥打端 UUID (AWS Chime)
   */
  clearOfferState_Demo({ callerUID, meetingId }) {
    console.log("=== clearOfferState : ", callerUID || meetingId);

    let cuid = callerUID ? `cuid=${callerUID}` : null;
    let mid = meetingId ? `mid=${meetingId}` : null;

    this.commonDelete2(`/demo/web/call_info?${cuid ?? mid}`);
  }

  // ======================= 共用請求函式 =======================

  commonGet(address, cb_ok, cb_err) {
    axios
      .get(`${this.url}${address}`)
      .then((res) => {
        if (res && res.status == 200) {
          cb_ok(res.data);
        } else {
          cb_err(res);
        }
      })
      .catch((err) => {
        console.log(err);
        cb_err(err);
      });
  }

  commonPost(address, data, cb_ok, cb_err) {
    axios
      .post(`${this.url}${address}`, data)
      .then((res) => {
        if (res && res.status == 200) {
          cb_ok(res.data);
        } else {
          cb_err(res);
        }
      })
      .catch((err) => {
        console.log(err);
        cb_err(err);
      });
  }

  commonPut(address, data, cb_ok, cb_err) {
    axios
      .put(`${this.url}${address}`, data)
      .then((res) => {
        if (res && res.status == 200) {
          cb_ok(res.data);
        } else {
          cb_err(res);
        }
      })
      .catch((err) => {
        console.log(err);
        cb_err(err);
      });
  }

  commonDelete(address, cb_ok, cb_err) {
    axios
      .delete(`${this.url}${address}`)
      .then((res) => {
        if (res && res.status == 200) {
          cb_ok(res.data);
        } else {
          cb_err(res);
        }
      })
      .catch((err) => {
        console.log(err);
        cb_err(err);
      });
  }

  // Promise 版請求回調方式

  async commonGet2(address, config = null) {
    const res = await axios.get(`${this.url}${address}`, config);
    const success = res && res.status == 200;
    return success ? res.data : Promise.reject(res);
  }

  async commonPost2(address, data) {
    const res = await axios.post(`${this.url}${address}`, data);
    const success = res && res.status == 200;
    return success ? res.data : Promise.reject(res);
  }

  async commonPut2(address, data) {
    const res = await axios.put(`${this.url}${address}`, data);
    const success = res && res.status == 200;
    return success ? res.data : Promise.reject(res);
  }

  async commonDelete2(address) {
    const res = await axios.delete(`${this.url}${address}`);
    const success = res && res.status == 200;
    return success ? res.data : Promise.reject(res);
  }

  commonGet_new(address, cb_ok, cb_err) {
    console.log(address);
    axios
      .get(`${this.url_new}${address}`)
      .then((res) => {
        return res && res.status == 200 ? cb_ok(res.data) : cb_err(res);
      })
      .catch((err) => {
        console.log(err);
        cb_err(err);
      });
  }

  commonPost_new(address, data, cb_ok, cb_err) {
    axios
      .post(`${this.url_new}${address}`, data)
      .then((res) => {
        return res && res.status == 200 ? cb_ok(res.data) : cb_err(res);
      })
      .catch((err) => {
        console.log(err);
        cb_err(err);
      });
  }

  commonPut_new(address, data, cb_ok, cb_err) {
    axios
      .put(`${this.url_new}${address}`, data)
      .then((res) => {
        return res && res.status == 200 ? cb_ok(res.data) : cb_err(res);
      })
      .catch((err) => {
        console.log(err);
        cb_err(err);
      });
  }

  commonDelete_new(address, cb_ok, cb_err) {
    axios
      .delete(`${this.url_new}${address}`)
      .then((res) => {
        return res && res.status == 200 ? cb_ok(res.data) : cb_err(res);
      })
      .catch((err) => {
        console.log(err);
        cb_err(err);
      });
  }
}

export default APIServer;
