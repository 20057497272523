import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import zhHant from "@/locale/zh-Hant.ts";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { zhHant },
    current: "zhHant",
  },
});
