// AWS Chime 功能模組
import { ConsoleLogger, DefaultDeviceController, DefaultMeetingSession, LogLevel, MeetingSessionConfiguration } from "amazon-chime-sdk-js";

import axios from "axios";

class AWSChimeServer {
  BASE_URL = "https://ujbbgbbl6a.execute-api.ap-northeast-1.amazonaws.com/prod/aws-chime";
  /** 會議會話類別 @type {DefaultMeetingSession} */
  meetingSession;
  /** 音訊輸入設備列表 @type {MediaDeviceInfo[]} */
  audioInputDevices;
  /** 音訊輸出設備列表 @type {MediaDeviceInfo[]} */
  audioOutputDevices;
  /** 影像輸入設備列表 @type {MediaDeviceInfo[]} */
  videoInputDevices;
  /** 本地影像容器 @type {HTMLVideoElement} */
  localVideoElement;
  /** 遠端影像容器 @type {HTMLVideoElement} */
  remoteVideoElement;
  /** 聲音容器 @type {HTMLAudioElement} */
  audioElement;
  /** 麥克風靜音狀態監聽器 @type {(muted: boolean) => void}*/
  micMutedListener;
  /** 連接狀態監聽器 @type {(connect: boolean) => void}*/
  connectListener;
  /** 連接狀態暫存 */
  connectState = false;
  /** 通話關閉監聽器 @type {() => void} */
  closeListener;
  /** 本地用戶 ID */
  localAttendeeId = "";

  /** 創建會議(發送通話邀請)
   * @param {string} name 用戶名稱
   * @param {string} hid 戶別ID
   */
  async createMeeting(name, hid = "") {
    hid = hid ? `&hid=${hid}` : hid;
    let url = `${this.BASE_URL}/create?name=${name}${hid}`;
    let res = await axios.get(url);
    let data = res.data;
    this._initMeeting(data.meetingResponse, data.attendeeResponse);
    await this._initMediaDevices();
    await this._startSession();
  }

  /** 加入會議
   *
   * @param {string} meetingId 會議ID
   */
  async joinMeeting(meetingId) {
    let url = `${this.BASE_URL}/jion?title=${meetingId}`;
    let res = await axios.get(url);
    let data = res.data;
    this._initMeeting(data.meetingResponse, data.attendeeResponse);
    await this._initMediaDevices();
    await this._startSession();
  }

  /** 初始化會議資訊 */
  _initMeeting(meetingResponse, attendeeResponse) {
    const configuration = new MeetingSessionConfiguration(meetingResponse, attendeeResponse);
    const logger = new ConsoleLogger("MyLogger", LogLevel.INFO);
    const deviceController = new DefaultDeviceController(logger);
    let meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
    let audioVideo = meetingSession.audioVideo;
    this.localAttendeeId = meetingSession.configuration.credentials.attendeeId;
    // 監聽出席者變更狀態
    audioVideo.realtimeSubscribeToAttendeeIdPresence((presentAttendeeId, present) => {
      if (present && this.localAttendeeId != presentAttendeeId) {
        // 連接成功
        this.connectListener?.(true);
        this.connectState = true;
      }
    });
    // 監聽生命週期
    audioVideo.addObserver({
      // 監聽影像來源
      videoTileDidUpdate: (tileState) => {
        if (!tileState.boundAttendeeId) return;
        if (this.localVideoElement && tileState.localTile) {
          // 本地影像
          audioVideo.bindVideoElement(tileState.tileId, this.localVideoElement);
        } else if (this.remoteVideoElement && !tileState.isContent) {
          // 遠端影像
          audioVideo.bindVideoElement(tileState.tileId, this.remoteVideoElement);
        }
      },
      // 監聽影像移除
      videoTileWasRemoved: (tileId) => audioVideo.unbindVideoElement(tileId),
      // 監聽會議關閉/離開
      audioVideoDidStop: async () => {
        await this._stopSession();
        this.meetingSession.destroy();
        this.closeListener?.();
      },
    });
    // 監聽自身麥克風靜音
    const presentAttendeeId = meetingSession.configuration.credentials.attendeeId;
    audioVideo.realtimeSubscribeToVolumeIndicator(presentAttendeeId, (attendeeId, volume, muted) => {
      if (muted != undefined) this.micMutedListener?.(muted);
    });
    this.meetingSession = meetingSession;
  }

  /** 關閉會議(結束通話) */
  async closeMeeting() {
    await this._stopSession();
    this.meetingSession.destroy();
    let meetingId = this.meetingSession.configuration.meetingId;
    let url = `${this.BASE_URL}/close?title=${meetingId}`;
    return await axios.get(url);
  }

  /** 設置本地影像容器
   * @param {HTMLVideoElement} element
   */
  setLocalVideoElement(element) {
    this.localVideoElement = element;
  }

  /** 設置遠端影像容器
   * @param {HTMLVideoElement} element
   */
  setRemoteVideoElement(element) {
    this.remoteVideoElement = element;
  }

  /** 設置聲音容器
   * @param {HTMLAudioElement} element
   */
  setAudioElement(element) {
    this.audioElement = element;
  }

  /** 初始化多媒體設備列表 (自動觸發權限請求) */
  async _initMediaDevices() {
    if (this.audioInputDevices) return;

    let audioVideo = this.meetingSession.audioVideo;
    this.audioInputDevices = await audioVideo.listAudioInputDevices();
    this.audioOutputDevices = await audioVideo.listAudioOutputDevices();
    this.videoInputDevices = await audioVideo.listVideoInputDevices();

    // 設置音頻輸出裝置
    if (this.audioOutputDevices.length) {
      await audioVideo.chooseAudioOutput(this.audioOutputDevices[0].deviceId);
    } else {
      console.log("=== 無聲音輸出裝置");
    }

    // 監聽設備變更狀態
    audioVideo.addDeviceChangeObserver({
      audioInputsChanged: (freshAudioInputDeviceList) => {
        // An array of MediaDeviceInfo objects
        freshAudioInputDeviceList.forEach((mediaDeviceInfo) => {
          console.log(`Device ID: ${mediaDeviceInfo.deviceId} Microphone: ${mediaDeviceInfo.label}`);
        });
      },

      audioOutputsChanged: (freshAudioOutputDeviceList) => {
        console.log("Audio outputs updated: ", freshAudioOutputDeviceList);
      },

      videoInputsChanged: (freshVideoInputDeviceList) => {
        console.log("Video inputs updated: ", freshVideoInputDeviceList);
      },

      audioInputMuteStateChanged: (device, muted) => {
        console.log("=== 麥克風靜音 : ", muted);
      },
    });
  }

  /** 開始會話 */
  async _startSession() {
    let audioVideo = this.meetingSession.audioVideo;
    // 啟用聲音輸入
    if (this.audioInputDevices.length) {
      await audioVideo.startAudioInput(this.audioInputDevices[0].deviceId);
    } else {
      console.log("=== 無麥克風輸入源");
    }
    // 設置多媒體元件
    audioVideo.bindAudioElement(this.audioElement);
    // 啟動
    audioVideo.start();
  }

  /** 停止會話 */
  async _stopSession() {
    // 停止
    this.meetingSession.audioVideo.stop();
    // 停用聲音輸入
    await this.meetingSession.audioVideo.stopAudioInput();
    await this.stopLocalVideo();
  }

  /** 開啟影像
   * @param {HTMLVideoElement} element 影像 DOM
   */
  async startLocalVideo() {
    if (this.videoInputDevices.length) {
      let audioVideo = this.meetingSession.audioVideo;
      await audioVideo.startVideoInput(this.videoInputDevices[0].deviceId);
      audioVideo.startLocalVideoTile();
    } else {
      console.log("=== 無影像輸入裝置");
    }
  }

  /** 停止影像
   * @param {HTMLVideoElement} element
   */
  async stopLocalVideo() {
    let audioVideo = this.meetingSession.audioVideo;
    audioVideo.stopLocalVideoTile();
    await audioVideo.stopVideoInput();
    audioVideo.removeLocalVideoTile();
  }

  /** 設置麥克風靜音狀態
   * @param {boolean} b
   * @returns {boolean} 是否設置成功
   */
  setMicMute(b) {
    let audioVideo = this.meetingSession.audioVideo;
    if (!b) return audioVideo.realtimeUnmuteLocalAudio();
    audioVideo.realtimeMuteLocalAudio();
    return true;
  }

  /** 設置狀態監聽器
   * @param {object} listener
   * @param {(muted: boolean) => void} listener.onMicMuted 監聽麥克風靜音狀態
   * @param {(connect: boolean) => void} listener.onConnect 監聽連接狀態
   * @param {(connect: boolean) => void} listener.onClose 監聽通話關閉
   * @param {(connect: boolean) => void} listener.onHasAudioInput 監聽通話關閉
   */
  setOnStateListener(listener) {
    const { onMicMuted, onConnect, onClose } = listener;
    let muted = this.meetingSession?.audioVideo.realtimeIsLocalAudioMuted();
    this.micMutedListener = onMicMuted;
    this.micMutedListener?.(!!muted);
    this.connectListener = onConnect;
    this.connectListener?.(this.connectState);
    this.closeListener = onClose;
  }
}

export default AWSChimeServer;
