<template>
  <div>
    <!-- 主體內容 -->
    <router-view />
    <!-- 消息通知欄 -->
    <v-snackbar v-model="showNotify" :color="notifyStatus" :timeout="notifyTimeout" top>
      <div>{{ notifyText }}</div>
    </v-snackbar>
    <!-- 雲對講視窗 -->
    <chat-room-dialog ref="chatRoom" />
    <!-- 雲對講-來電鈴聲 -->
    <audio ref="audio" loop src="@/assets/audio/ring-tone.mp3"></audio>
    <!-- 雲對講-來電鈴聲(緊急) -->
    <audio ref="audioUrgent" loop src="@/assets/audio/alarm-tone.wav"></audio>
    <!-- 智慧助理 -->
    <smart-assistant v-if="$store.getters.getUser" />
    <!-- 雲對講視窗(AWS Chime) -->
    <chime-dialog ref="chimeDialog" />
  </div>
</template>

<script>
import Vue from "vue";
import ChatRoomDialog from "@/components/ChatRoomDialog.vue";
import ChimeDialog from "@/components/ChatRoomDialog_Chime.vue";
import SmartAssistant from "@/components/SmartAssistant.vue";
import FirebaseServer from "@/lib/firebase-server";

export default {
  components: {
    ChatRoomDialog,
    ChimeDialog,
    SmartAssistant,
  },
  name: "App",
  data() {
    return {
      showNotify: false,
      notifyStatus: "",
      notifyText: "",
      notifyTimeout: 1000,
    };
  },

  mounted() {
    document.title = this.$vuetify.lang.t("$vuetify.appName") + " - " + this.$vuetify.lang.t("$vuetify.slogan");

    // 共用 Toast ，使用方式 :
    // 成功 - $showSuccessNotify(msg)
    // 失敗 - $showErrorNotify(msg)
    Vue.prototype.$showSuccessNotify = this.showSuccessNotify;
    Vue.prototype.$showErrorNotify = this.showErrorNotify;
    // this.$vuetify.theme.dark = true;

    // 註冊離開頁面監聽事件
    window.addEventListener("beforeunload", this.handleBeforeUnload);

    // 檢查是否支援通知功能
    // if ("Notification" in window) {
    //   this.startOnOfferListener();
    // } else {
    //   this.startOnOfferListener_Demo();
    // }

    // 暫時使用 Demo 監聽(因為音箱暫時只能用他)
    this.startOnOfferListener_Demo();
  },
  beforeDestroy() {
    // 註銷離開頁面監聽事件
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  methods: {
    showSuccessNotify(text) {
      this.notifyStatus = "#43A047";
      this.notifyText = text;
      this.notifyTimeout = 4000;
      this.showNotify = true;
    },
    showErrorNotify(text) {
      this.notifyStatus = "#B71C1C";
      this.notifyText = text;
      this.notifyTimeout = 4000;
      this.showNotify = true;
    },

    /** 頁面關閉事件 */
    handleBeforeUnload() {
      // 移除裝置資訊
      let baseUrl = process.env.VUE_APP_SERVER_URL;
      let did = window.localStorage.getItem("deviceInfoId");
      if (!did) return;
      let cid = this.$store.getters.getSelectCommunity.id;
      let token = this.$store.getters.getToken;
      let data = JSON.stringify({ did });
      navigator.sendBeacon(`${baseUrl}/community/${cid}/device_info/remove?token=${token}`, data);
    },

    /** [Demo] 開始監聽雲對講通話請求 */
    startOnOfferListener_Demo() {
      this.$api.setOnOfferStateListener((data) => {
        // 更新狀態有值時才顯示通知訊息，否則將其關閉
        if (data) {
          if (data.urgent) {
            let bb = (text, size = 30) => {
              return `<b style="color: red;font-size: ${size}px;">${text}</b>`;
            };
            let tt = (text) => {
              return `<p style="font-weight: normal; display: inline;">${text}</p>`;
            };

            // 顯示來緊急通話
            this.$refs.audioUrgent.currentTime = 0;
            this.$refs.audioUrgent.play();
            this.$swal({
              title: `
              ${bb("緊 急 通 話 !", 37)}

              ${tt(`收到來至 A1棟1號4樓 ${data.callerName}`)}
              ${bb("緊急通話")}${tt("請求")}`,
              text: "將於 5 秒後自動接聽",
              showDenyButton: true,
              denyButtonColor: "#4caf50",
              denyButtonText: "接聽",
              confirmButtonColor: "#f44336",
              confirmButtonText: "拒接",
              allowOutsideClick: false,
              timer: 5 * 1000,
              timerProgressBar: true,
            }).then((result) => {
              // 若時間到將自動接聽
              if (result.isDismissed || result.isDenied) {
                if (data.meetingId) {
                  // AWS Chime
                  this.$refs.chimeDialog.sendAnswer(data);
                } else {
                  // WebRTC
                  this.$refs.chatRoom.sendAnswer(data);
                }
              }
              this.$api.clearOfferState_Demo(data);
              this.$refs.audioUrgent.pause();
            });
            return;
          } else {
            // 顯示來電通話訊息
            this.$refs.audio.currentTime = 0;
            this.$refs.audio.play();
            this.$swal({
              icon: "info",
              title: `收到來至 ${data.callerName} 的通話請求`,
              showDenyButton: true,
              denyButtonColor: "#4caf50",
              denyButtonText: "接聽",
              confirmButtonColor: "#f44336",
              confirmButtonText: "拒接",
              allowOutsideClick: false,
              timer: 15 * 1000,
              timerProgressBar: true,
            }).then((result) => {
              if (result.isDenied) {
                if (data.meetingId) {
                  // AWS Chime
                  this.$refs.chimeDialog.sendAnswer(data);
                } else {
                  // WebRTC
                  this.$refs.chatRoom.sendAnswer(data);
                }
              }
              this.$api.clearOfferState_Demo(data);
              this.$refs.audio.pause();
            });
          }
        } else {
          this.$swal.close();
        }
      });
    },

    /** [FCM] 開始監聽雲對講通話請求 */
    startOnOfferListener() {
      FirebaseServer.addOnMessageListener("intercom-call", async (payload) => {
        let data = payload.data;
        let res = await this.$api.getIntercomCallInfoSDP(data.callerUID);
        data["sdp"] = res.sdp;

        // 顯示來電通話訊息
        this.$refs.audio.currentTime = 0;
        this.$refs.audio.play();
        this.$swal({
          icon: "info",
          title: `收到來至 ${data.callerName} 的通話請求`,
          showDenyButton: true,
          denyButtonColor: "#4caf50",
          denyButtonText: "接聽",
          confirmButtonColor: "#f44336",
          confirmButtonText: "拒接",
          allowOutsideClick: false,
          timer: 15 * 1000,
          timerProgressBar: true,
        }).then((result) => {
          if (result.isDenied) {
            this.$refs.chatRoom.sendAnswer(data);
          }
          this.$refs.audio.pause();
        });
      });
    },
  },
};
</script>

<style>
div {
  font-family: Circle !important;
  font-size: 17px;
}

.theme {
  background: linear-gradient(147deg, #42a9b9 0%, #33a6b8 67%);
}
</style>

<!-- <style>
@import "./assets/font/font.css";
div {
  font-family: Circle;
}
</style> -->
