<!-- 雲對講視窗 Chime 版 -->
<template>
  <v-dialog v-model="isShow" max-width="800px" ref="dialog" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2" :style="{ height: $store.getters.isSpeaker ? '48px' : '68px' }">
        <span class="text-h6 mr-1"> 雲對講視窗 - {{ selectHouseHold?.HouseHold || answerData?.callerName }} </span>
        <span>
          (<span class="text-h6" :style="{ color: isConnected ? 'green' : 'gray' }"> {{ isConnected ? "連接成功" : "連接中⋯" }} </span>)
        </span>
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-container>
        <!-- 影像顯示 -->
        <v-row>
          <!-- 影像預覽區 -->
          <v-col cols="6">
            <div v-if="!$store.getters.isSpeaker" class="text-center pb-1">影像預覽</div>
            <div class="cam-box">
              <video class="cam-content" ref="localVideo" autoplay playsinline></video>
            </div>
          </v-col>
          <!-- 遠端影像區 -->
          <v-col cols="6">
            <div v-if="!$store.getters.isSpeaker" class="text-center pb-1">遠端影像</div>
            <div class="cam-box">
              <video class="cam-content" ref="remoteVideo" autoplay playsinline></video>
            </div>
          </v-col>
        </v-row>

        <!-- 控制面板 -->
        <v-row>
          <v-col class="text-center">
            <v-btn icon outlined x-large :color="isAudio ? null : `red`" @click="_changeAudioState">
              <v-icon>{{ isAudio ? "mdi-microphone" : "mdi-microphone-off" }}</v-icon>
            </v-btn>
            <v-btn class="ml-5 mr-5" icon outlined x-large :color="isVideo ? `blue` : null" @click="_changeVideoState">
              <v-icon>{{ isVideo ? "mdi-video" : "mdi-video-off" }}</v-icon>
            </v-btn>
            <v-btn icon outlined x-large color="red" @click="_hangup"><v-icon>mdi-phone-hangup</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <audio ref="myAudio"></audio>
  </v-dialog>
</template>

<script>
import AWSChimeServer from "@/lib/aws-chime-server";

export default {
  name: "ChatRoomDialog",
  data: () => ({
    /** 視窗顯示狀態 */
    isShow: false,
    /** @type {AWSChimeServer} */
    chimeServer: null,

    // == WebCam ==

    isError: false,
    isCamPlaying: false,
    camera: null,
    selectCamDevice: null,
    /** 是否有聲音輸入 */
    hasAudioInput: false,

    // == WebRTC ==

    /** 連接狀態 */
    isConnected: false,
    /** 是否為通話請求，true : 發送通話、false : 接受通話 */
    isOffer: null,
    /** 麥克風啟用狀態 */
    isAudio: true,
    /** 攝像頭啟用狀態 */
    isVideo: false,
    /** 通話連接超時(s) */
    connectedTimeout: 30,
    /** 選擇的戶別資料 */
    selectHouseHold: null,
    /** 收到的通話請求資料 */
    answerData: null,
    /** 連線等待計時器ID @type {NodeJS.Timeout} */
    connectedTimeoutId: null,
  }),
  mounted() {
    this._initChimeServer();
  },
  methods: {
    // ===== 外部方法 =====

    /** 發送通話及顯示通話視窗
     * @param houseHold 戶別資料
     */
    sendOffer(houseHold) {
      console.log("=== Chime - 發送通話請求 : ", houseHold);
      this.isOffer = true;
      this.selectHouseHold = houseHold;
      this.isShow = true;
      this.isAudio = true;
      this.isVideo = false;
      this.isConnected = false;
      this.chimeServer
        .createMeeting("管理中心", houseHold.id)
        .then(() => {
          console.log("=== Chime - 完成 : ", this.chimeServer.meetingSession.configuration.meetingId);
          this._startTime();
        })
        .catch((err) => {
          console.error(err);
          this.isShow = false;
          this.chimeServer.closeMeeting();
          this.$swal({ icon: "error", text: "建立通話失敗" });
        });
    },

    /** 接收通話及顯示通話視窗
     * @param {string} callerName 撥號端名稱
     * @param {string} meetingId 會議ID
     */
    sendAnswer({ callerName, meetingId }) {
      console.log("Chime - 接收通話請求 : ");
      this.isOffer = false;
      this.answerData = { callerName, meetingId };
      this.isShow = true;
      this.isAudio = true;
      this.isVideo = false;
      this.isConnected = false;
      this.chimeServer
        .joinMeeting(meetingId)
        .then(() => {
          console.log("=== Chime - 完成 : ", this.chimeServer.meetingSession.configuration.meetingId);
        })
        .catch((err) => {
          console.error(err);
          this.isShow = false;
          this.chimeServer.closeMeeting();
          this.$swal({ icon: "error", text: "建立通話失敗" });
        });
    },

    // ===== 內部方法 =====

    /** 初始化 Chime */
    _initChimeServer() {
      console.log("Chime - 初始化... ");
      this.chimeServer = new AWSChimeServer();

      this.chimeServer.setOnStateListener({
        onConnect: (connect) => {
          this.isConnected = connect;
          clearTimeout(this.connectedTimeoutId);
        },
        onMicMuted: (muted) => (this.isAudio = !muted),
        onClose: this._close,
      });
    },

    /** 開始計算通話請求超時 */
    _startTime() {
      this.connectedTimeoutId = setTimeout(() => {
        this._hangup();
        this.$emit("connect-timeout", this.selectHouseHold);
      }, this.connectedTimeout * 1000);
    },

    /** [事件] 變更麥克風啟用狀態 */
    _changeAudioState() {
      console.log("=== _changeAudioState : ", this.isAudio);

      this.chimeServer.setMicMute(this.isAudio);
    },

    /** [事件] 變更影像啟用狀態 */
    _changeVideoState() {
      this.isVideo = !this.isVideo;
      if (this.isVideo) {
        this.chimeServer.startLocalVideo();
      } else {
        this.chimeServer.stopLocalVideo();
      }
    },

    /** [事件] 結束通話 */
    _hangup() {
      this.isShow = false;
      this.chimeServer.closeMeeting();
      clearTimeout(this.connectedTimeoutId);
    },

    /** [事件] 關閉 */
    _close() {
      if (!this.isShow) return;

      this.isShow = false;
      if (this.$refs.remoteVideo) {
        this.$refs.remoteVideo.srcObject = null;
      }

      // 關閉計時器
      clearTimeout(this.connectedTimeoutId);

      // 顯示結束通話訊息
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        })
        .fire({
          icon: "success",
          title: "已結束通話",
        });
    },
  },
  watch: {
    isShow(value) {
      if (value) {
        this.$nextTick(() => {
          this.chimeServer.setLocalVideoElement(this.$refs.localVideo);
          this.chimeServer.setRemoteVideoElement(this.$refs.remoteVideo);
          this.chimeServer.setAudioElement(this.$refs.myAudio);
        });
      }
    },
  },
};
</script>

<style>
.cam-box {
  width: 100%;
  padding-top: 75%;
  position: relative;
  box-shadow: 0 0 0 2px rgba(170, 170, 170);
  background-color: rgba(170, 170, 170);
}
.cam-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cam-content2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
